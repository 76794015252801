import { Button, Drawer, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { MasterLink, MasterLinkList } from "./types";
import { EditOutlined, LinkOutlined, PlusSquareOutlined } from "@ant-design/icons";
import CreateMasterLinks from "./CreateMasterLinks";
import { useAppContext } from "@/components/AppContext";
import { A4B_APPS } from "@/utils/constants";
import { CreateStore } from "@a4b/api/src/modules/Monetisation/Puja/types";


interface ManageMasterLinksProps {
    setMasterLinkDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    showMasterLinkDrawer: boolean;
    templeList: CreateStore[];
    showCreateMasterLinkModal: () => void
}
const ManageMasterLinks: React.FC<ManageMasterLinksProps> = ({ showMasterLinkDrawer, setMasterLinkDrawer, templeList, showCreateMasterLinkModal }) => {
    const [masterLinks, setMasterLinks] = useState<MasterLinkList | undefined>();
    const [showLinkCreateModal, setShowLinkCreateModal] = useState<{ show: boolean, masterLink: MasterLink | null }>({ show: false, masterLink: null })
    const { networkInstance, app } = useAppContext()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi



    const getMasterLinks = async () => {
        if (!templeList) { return }
        try {
            const masterLinkList = await monetisationPujaApi?.storesApi?.getMasterLinks()
            const masterLinks = masterLinkList?.data?.data?.master_slugs?.map((masterLink: MasterLink) => {
                const storeData = templeList?.find((store: CreateStore) => store.id === masterLink?.store_id!)
                return { ...masterLink, title: storeData?.title }
            })
            setMasterLinks({ master_slugs: masterLinks })
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        getMasterLinks()
    }, [templeList, showMasterLinkDrawer])

    const getMasterLinkButton = (record: MasterLink) => {

        return <div style={{ marginLeft: "2px", marginRight: "2px" }}>
            <Tag style={{ cursor: "pointer" }} color={record?.active ? "success" : "error"} onClick={() => setShowLinkCreateModal({ show: true, masterLink: record })} >
                {record?.id && <EditOutlined />}
                <LinkOutlined /> Links </Tag>
        </div>
    }
    const callbackFromCreateMasterLink = (linkData: { show: boolean, masterLink: MasterLink | null }) => {
        setShowLinkCreateModal({ show: linkData?.show, masterLink: linkData?.masterLink })
        getMasterLinks()
    }
    const getFilters = () => {
        return masterLinks?.master_slugs?.map((item: MasterLink) => ({ value: item?.store_id, text: item?.store_id, key: item?.store_id }))
    }
    const columnsMasterLinks = [
        {
            title: "Store Name",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Store Id with filter",
            dataIndex: "store_id",
            key: "store_id",
            filterMode: "menu",
            filterSearch: true,
            filters: getFilters(),
            onFilter: (value: string | number, record: any) => {
                return String(record.store_id) === String(value); // Compare `value` to `record.store_id`
            }
        },
        {
            title: "Slug",
            dataIndex: "slug",
            key: "slug",
        },
        {
            title: "Status",
            dataIndex: "active",
            key: "active",
            render: (active: boolean) => (active ? "Active" : "Inactive"), // Render true/false as Active/Inactive
        },
        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any, index: any) => getMasterLinkButton(record), // Render true/false as Active/Inactive
        },
    ];

    return (
        <>
            <Drawer
                title="Master Links"
                placement="right"
                onClose={() => {
                    setMasterLinkDrawer(false);
                }}
                visible={showMasterLinkDrawer}
                destroyOnClose={true}
                width={"60%"}
                extra={<Tag onClick={() => showCreateMasterLinkModal()} style={{ cursor: "pointer" }} color="#2db7f5">Create masterlink</Tag>}
            >
                <Table
                    dataSource={masterLinks?.master_slugs || []} // Pass the `master_slugs` array as dataSource
                    columns={columnsMasterLinks as any}
                    rowKey="id" // Use a unique key for table rows

                />
            </Drawer>
            <CreateMasterLinks show={showLinkCreateModal?.show} masterLink={showLinkCreateModal?.masterLink} linkCreateCallback={callbackFromCreateMasterLink} templeList={templeList} />
        </>
    );
};

export default ManageMasterLinks;