import { Button, Modal, Form, Input, Switch, notification, Tag, Row, Col, Popconfirm, Select } from "antd";
import { useEffect, useState } from "react";
import { MasterLink } from "./types";
import { useAppContext } from "@/components/AppContext";
import { A4B_APPS } from "@/utils/constants";
import { CreateStore } from '@a4b/api/src/modules/Monetisation/Puja/types';
interface CreateMasterLinksProps {
    show: boolean;
    masterLink: MasterLink | null;
    linkCreateCallback: (linkData: { show: boolean; masterLink: MasterLink | null }) => void;
    templeList: CreateStore[]
}

const CreateMasterLinks: React.FC<CreateMasterLinksProps> = ({ show, masterLink, linkCreateCallback, templeList }) => {

    const { networkInstance, app } = useAppContext();
    const monetisationPujaApi =
        app === A4B_APPS.SRIMANDIR
            ? networkInstance.clientWithHeaders.monetisationPujaApi
            : networkInstance.clientWithHeaders.monetisationAstroPujaApi;

    const [form] = Form.useForm();
    const [isFormValid, setIsFormValid] = useState(true); // Track form validity
    const [prefix] = useState("popular-");


    useEffect(() => handleFieldsChange())
    const handleSubmit = async (formValues: any) => {
        try {
            let response
            if (!masterLink?.id) {

                response = await monetisationPujaApi?.storesApi?.createMasterLink({ ...formValues, slug: `${prefix}${formValues?.slug}`, active: !formValues?.active ? false : true });
            } else {

                response = await monetisationPujaApi?.storesApi?.updateMasterLink(masterLink?.id!, { ...formValues, id: masterLink?.id });

            }

            notification.success({
                message: "Success",
                description: masterLink?.id ? "Master link updated successfully." : "Master link created successfully.",
            });
            onCancel(); // Close modal after successful submission
        } catch (error) {

            notification.error({
                message: "Error",
                description: "Failed to create master link. Please try again.",
            });
        }
    };

    const onCancel = () => {
        form.resetFields(); // Reset form fields on cancel
        linkCreateCallback({ show: false, masterLink: null });
    };

    useEffect(() => {
        if (show) {
            if (masterLink) {
                form.setFieldsValue(masterLink);
            } else {
                form.resetFields(); // Reset the form if masterLink is null
            }
        }
    }, [show, masterLink, form]);

    const handleFieldsChange = () => {
        const hasErrors = form.getFieldsError().some(({ errors }) => errors.length > 0);
        setIsFormValid(!hasErrors); // Update form validity
    };

    return (
        <Modal
            visible={show}
            footer={false}
            destroyOnClose
            title={masterLink?.id ? "Update Master Link" : "Create Master Link"}
            onCancel={onCancel}

        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onFieldsChange={handleFieldsChange} // Monitor field changes
            >
                {/* Store ID (Pre-filled and Read-Only) */}
                {<Form.Item label="Store Title" name="store_id" >
                    <Select
                        placeholder="Search to Store Id"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: string, option: any) =>
                            option?.value?.toString().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                    >
                        {templeList?.map((store: CreateStore) => < Select.Option key={store?.id} value={store?.id}>{store?.title}</Select.Option>)}
                    </Select>
                </Form.Item>
                }


                {/* Slug Field with Prefix */}
                <Form.Item
                    label={<span>Slug <Tag color="error">(caution: non-editable field)</Tag></span>}
                    name="slug"
                    rules={[
                        { required: true, message: "Slug is required!" },
                        { pattern: /^[a-z0-9-]+$/, message: "Slug must be lowercase and contain no spaces,no underscore!" },
                    ]}

                >
                    <Input placeholder="Enter slug" addonBefore={masterLink?.id ? "" : prefix} readOnly={masterLink?.id ? true : false} disabled={masterLink?.id ? true : false} />

                </Form.Item>
                <div>



                </div>
                {/* Active/Deactive Switch */}
                <Form.Item
                    label="Link Status"
                    name="active"
                    valuePropName="checked" // Maps boolean to Switch's checked state
                >
                    <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                </Form.Item>

                <Form.Item >
                    <Row gutter={[0, 8]} justify="start">
                        <Col span={18} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button key="back" onClick={onCancel}>
                                Cancel
                            </Button>
                        </Col>
                        <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Popconfirm
                                title="Once a slug is created, it cannot be edited, so please proceed with caution."
                                okText="Proceed"
                                cancelText="Cancel"
                                onConfirm={() => form?.submit()}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={!isFormValid} // Disable if form is invalid
                                >
                                    {masterLink?.id ? "Update" : "Submit"}
                                </Button>
                            </Popconfirm>
                        </Col>
                    </Row>


                </Form.Item>
            </Form>
        </Modal >
    );
};

export default CreateMasterLinks;