import React, { useState } from 'react'
import {
    Table,
    Modal,
    Tag,
    Button,
    Image,
    Tooltip,
    Descriptions,
    Spin
} from 'antd'
import { CheckCircleOutlined, MailOutlined, NotificationOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Order } from '@a4b/api/src/modules/Monetisation/Puja/types'
import { CustomLoader } from '@/components/Loader'

interface FiltersType {
    serviceType: string
    orderStatus: string
    bookingId: string
    contactNumber: string
    userId: string
    storeId: string | undefined
    bookingWhatsAppNumber: string
}
interface Props {
    orderData: Order[] | undefined
    handleTableChange: (type: string) => void
    sendNotification: (record: any) => void
    filters: FiltersType
    triggerEdit: (value: any) => void
    triggerReschedule: (value: any) => void
    triggerRescheduleView: (value: any) => void
    refundHandler: (value: any) => void
    userRole: string
    loading: boolean
    currentPage: number | null
}
interface Address {
    pin_code: string
    city_name: string
    state_name: string
    house_info: string
    area_info: string
    landmark: string
}
interface AddressButtonProps {
    booking_address: Address
}

const OrderList: React.FC<Props> = ({
    orderData,
    handleTableChange,
    sendNotification,
    filters,
    triggerEdit,
    triggerReschedule,
    triggerRescheduleView,
    refundHandler,
    userRole,
    loading,
    currentPage
}) => {
    const dataSource = orderData

    const ShowMemberData = ({ booking_member_data }: any) => {
        const [visible, setVisible] = useState(false)

        const showAddressModal = () => {
            setVisible(true)
        }

        const handleCancel = () => {
            setVisible(false)
        }

        const subTableColumns = [
            {
                title: 'Full Name',
                dataIndex: 'full_name',
                key: 'full_name',
                // align: 'center',  
            },
            {
                title: 'Gotra',
                dataIndex: 'gotra',
                key: 'gotra',
                // align: 'center',  
            },
            {
                title: 'Mobile Number',
                dataIndex: 'mobile_number',
                key: 'mobile_number',
                // align: 'center',  
            },
        ]
        return (
            <>
                {(booking_member_data && booking_member_data?.length) ? <Button type="primary" onClick={showAddressModal}>Member Data</Button> : <p>Empty</p>}
                <Modal
                    title="Member data"
                    visible={visible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Table
                        columns={subTableColumns}
                        dataSource={booking_member_data}
                        pagination={false} />
                </Modal>
            </>
        );
    }

    const AddressButton: React.FC<AddressButtonProps> = ({ booking_address }) => {
        const [visible, setVisible] = useState(false)

        const showAddressModal = () => {
            setVisible(true)
        }

        const handleCancel = () => {
            setVisible(false)
        }

        return (
            <>
                <Button type="primary" onClick={showAddressModal}>Address</Button>
                <Modal
                    title="Address"
                    visible={visible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Descriptions
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1 }}
                    >
                        <Descriptions.Item label="Pincode">{booking_address?.pin_code}</Descriptions.Item>
                        <Descriptions.Item label="City">{booking_address?.city_name}</Descriptions.Item>
                        <Descriptions.Item label="State">{booking_address?.state_name}</Descriptions.Item>
                        <Descriptions.Item label="House">{booking_address?.house_info}</Descriptions.Item>
                        <Descriptions.Item label="Area">{booking_address?.area_info}</Descriptions.Item>
                        <Descriptions.Item label="Landmark">{booking_address?.landmark}</Descriptions.Item>
                    </Descriptions>
                </Modal>
            </>
        )
    }

    const SKUButton = ({ items, title }: any) => {
        const [visible, setVisible] = useState(false);

        const showSKUModal = () => {
            setVisible(true)
        }

        const handleCancel = () => {
            setVisible(false)
        }

        const skuTableColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                // align: 'center',  
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                // align: 'center',  
                render: (name: string) => <Tag color='green'>{name}</Tag>
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                // align: 'center',  
            },
            {
                title: 'Image',
                dataIndex: 'image_url',
                key: 'image_url',
                // align: 'center',  
                render: (name: string) => <Image src={name} width={80} height={80} />
            },
        ]

        return (
            <>
                <Button type="primary" onClick={showSKUModal}>{title}</Button>
                <Modal
                    title={title}
                    visible={visible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Table
                        columns={skuTableColumns}
                        dataSource={items}
                        pagination={false} />
                </Modal>
            </>
        )
    }

    const Notification = ({ record }: any) => (<Tag onClick={() => sendNotification(record)} icon={<NotificationOutlined />} color='cyan' style={{ cursor: 'pointer' }}>Notify</Tag>)

    const commonColumns = [
        {
            title: 'Order Id',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>),
        },
        {
            title: 'Booking Id',
            dataIndex: 'booking_id',
            key: 'booking_id',
            fixed: 'left',
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            fixed: 'left',
        },
        // {
        //     title: 'Store',
        //     dataIndex: 'store_name',
        //     key: 'store_name',
        //     // width: 200,
        //     ellipsis: true,
        // },
        {
            title: 'User Id',
            dataIndex: 'user_id',
            key: 'user_id',
            ellipsis: {
                showTitle: false,
            },
            render: (user_id: string) => (
                <Tooltip placement="topLeft" title={user_id}>
                    {user_id}
                </Tooltip>),
        },
        {
            title: 'Store Id',
            dataIndex: 'store_id',
            key: 'store_id',
            ellipsis: {
                showTitle: false,
            },
            render: (store_id: string) => (
                <Tooltip placement="topLeft" title={store_id}>
                    {store_id}
                </Tooltip>),
        },
        {
            title: 'Store Name',
            dataIndex: 'store_name',
            key: 'store_name',
            ellipsis: {
                showTitle: false,
            },
            render: (store_name: string) => (
                <Tooltip placement="topLeft" title={store_name}>
                    {store_name}
                </Tooltip>),
        },
        {
            title: 'Order Status',
            dataIndex: 'order_status',
            key: 'order_status',
            width: 150,
            render: (text: string) => {
                if (text) {
                    return (
                        <Tag color='green' key={text} style={{ width: 'fit-content' }}>
                            {text.toUpperCase()}
                        </Tag>
                    )
                }
                return "-"
            }
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            // width: 150,
            render: (text: string) => {
                if (text) {
                    const date = new Date(text);
                    const day = date.getUTCDate().toString().padStart(2, '0');
                    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
                    const year = date.getUTCFullYear().toString();
                    return `${day}-${month}-${year}`
                }
                return "-"
            }
        },
        {
            title: 'User name',
            dataIndex: 'user_name',
            key: 'user_name',
            // width: 150,
            ellipsis: true,
            render: (text: string) => {
                if (text) {
                    return text
                }
                return "-"
            }
        },
        {
            title: 'Contact',
            dataIndex: 'contact_number',
            key: 'contact_number',
            render: (text: string) => {
                if (text) {
                    return (
                        <a href={`tel:${text}`}>
                            {text}
                        </a>
                    )
                }
                return "-"
            }
        },
        {
            title: 'SKU',
            dataIndex: 'items',
            key: 'items',
            // width: 150,
            render: (text: string, record: any) => (
                <SKUButton items={record?.items} title='SKU Items' />
            ),
        },
    ]

    const editButton = {
        title: 'Edit',
        dataIndex: 'edit',
        key: 'edit',
        fixed: 'right',
        render: (text: string, record: any) =>
            <Button
                type='link'
                color='cyan'
                style={{ cursor: 'pointer' }}
                onClick={() => triggerEdit(record)}
            >
                Edit
            </Button>

    }

    const refundButton = {
        title: 'Refund',
        dataIndex: 'refund',
        key: 'refund',
        render: (text: string, record: any) => {
            if (record?.order_status === 'refunded') {
                return <Tag
                    color='#00BD68'
                    style={{ cursor: 'pointer' }}
                >
                    Refunded<CheckCircleOutlined style={{ marginLeft: '5px' }} />
                </Tag>
            } else {
                return <Button
                    type='primary'
                    style={{ cursor: 'pointer' }}
                    onClick={() => refundHandler(record)}
                >
                    Refund
                </Button>
            }
        }
    }

    const offeringName = {
        title: 'Offerings username',
        dataIndex: 'offerings_user_name',
        key: 'offerings_user_name',
        render: (text: string, record: any) => {
            if (text) {
                return text
            } else {
                return '-'
            }
        }
    }

    const addOns =
    {
        title: 'Add Ons',
        dataIndex: 'add_ons',
        key: 'add_ons',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        render: (text: string, record: any) => (
            <SKUButton items={record?.add_ons} title='Add Ons' />
        ),
    }

    let columns = []
    if (filters?.serviceType === 'pooja') {
        columns =
            [
                ...commonColumns,
                addOns,
                {
                    title: (
                        <>
                            <WhatsAppOutlined width={30} height={30} color='green' /> WA Number
                        </>
                    ),
                    dataIndex: 'booking_whatsapp_number',
                    key: 'booking_whatsapp_number',
                    // width: 150,
                    render: (text: string) => {
                        if (text) {
                            return (
                                <a href={`tel:${text}`}>
                                    {text}
                                </a>
                            )
                        }
                        return "-"
                    }
                },
                {
                    title: (
                        <>
                            <MailOutlined width={30} height={30} color='green' /> User Email
                        </>
                    ),
                    dataIndex: 'user_email',
                    key: 'user_email',
                    // width: 150,
                    render: (text: string) => {
                        if (text) {
                            return (
                                <span style={{ color: '#1890ff' }}>
                                    {text}
                                </span>
                            )
                        }
                        return "-"
                    }
                },
                {
                    title: 'Member data',
                    key: 'booking_member_data',
                    dataIndex: 'booking_member_data',
                    width: 150,
                    render: (text: string, record: any) => {
                        return <ShowMemberData booking_member_data={record?.booking_member_data} />
                    }
                },
                {
                    title: 'Opted Prasad',
                    dataIndex: 'is_opted_for_prasad',
                    key: 'is_opted_for_prasad',
                    render: (text: boolean) => {
                        if (String(text) === 'true') {
                            return <Tag color='green' >
                                {String(text)}
                            </Tag>
                        } else if (String(text) === 'false') {
                            return <Tag color='red'>
                                {String(text)}
                            </Tag>
                        }
                        return "-"
                    },
                },
                {
                    title: 'Address',
                    key: 'booking_address',
                    dataIndex: 'booking_address',
                    render: (text: string, record: any) => {
                        if (record?.is_opted_for_prasad) {
                            return <AddressButton booking_address={record?.booking_address} />
                        } else {
                            return <p>Empty</p>
                        }
                    },
                },
                {
                    title: 'Reschedule',
                    dataIndex: 'reschedule',
                    key: 'reschedule',
                    width: '7%',
                    render: (text: string, record: any) => {
                        if (record?.order_status !== "rescheduled") {
                            return <Button
                                type='primary'
                                color='cyan'
                                style={{ cursor: 'pointer' }}
                                onClick={() => triggerReschedule(record)}
                            >
                                Reschedule
                            </Button>
                        } else {
                            return <Button
                                type='link'
                                style={{ cursor: 'pointer' }}
                                onClick={() => triggerRescheduleView(record)}
                            >
                                <span style={{ textDecoration: 'underline', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    Rescheduled details..
                                </span>
                            </Button >
                        }
                    }
                },
                ...(((userRole === 'super_admin' && filters?.orderStatus === 'booked') && refundButton) ? [refundButton] : []),
                {
                    title: 'Notify',
                    key: 'booking_member_data',
                    dataIndex: 'booking_member_data',
                    fixed: 'right',
                    render: (text: string, record: any) => {
                        if (!record?.booking_member_data || record?.booking_member_data === '' || record?.booking_member_data?.length === 0) {
                            return <Notification record={record} />
                        }
                        return "-"
                    },
                },
                editButton
            ]
    }
    else {
        const columnsCopy: any = [...commonColumns]
        columnsCopy.splice(3, 0, offeringName)
        if (userRole === 'super_admin' && filters?.orderStatus === 'booked') {
            columnsCopy.push(refundButton)
        }
        columnsCopy.push(editButton)
        columns = columnsCopy
    }

    return (
        <>
            <Table
                dataSource={dataSource}
                columns={columns}
                scroll={{ x: 2400 }}
                size='large'
                loading={{
                    spinning: loading,
                    indicator: <Spin indicator={<CustomLoader />} />,
                }}
                pagination={false}
            />
            {
                currentPage &&
                <div style={{ display: 'flex', justifyContent: 'right', width: '100%', paddingTop: '10px' }}>
                    <Button onClick={() => handleTableChange('previous')}>Prev</Button>
                    <Button style={{ marginLeft: '5px', marginRight: '5px' }}>{String(currentPage)}</Button>
                    <Button onClick={() => handleTableChange('next')}>Next</Button>
                </div>
            }
        </>
    )
}

export default OrderList
