//context
//hoc
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'

//organisms
//hooks
//types
import React, { useEffect, useState } from 'react'
import { createSearchParams, useSearchParams } from 'react-router-dom'

import CreateTemplePage from '@a4b/ui/src/modules/monetisation/organisms/CreateTemple'

import CreateItem from '../CreateItem'
import AssigningOfferStep from './screens/AssigningOfferStep'
import FaqsAndReviewStep from './screens/FaqsAndReviewStep'
import TempleCreationStep from './screens/TempleCreationStep'

interface Props { }
const getPageBreadcrumbs = () => [
  {
    path: '/monetisation/offerings/temples',
    breadcrumbName: 'Manage Stores',
  },
  {
    path: '/offerings/temple/create',
    breadcrumbName: 'Create Store',
  },
]

const CreateTempleScreen: React.FC<Props> = () => {
  const { languageState } = useAppContext()
  const [showItemCreation, setShowItemCreation] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [currentStore, setCurrentStore] = useState('')
  const [isEditJourney, setIsEditJourney] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const current_step = searchParams.get('current_step')
    setCurrentStep(Number(current_step))

    const isEdit = searchParams.get('edit')
    if (!isEdit) {
      return
    }
    setIsEditJourney(true)
  }, [searchParams])

  useEffect(() => {
    const store_id = searchParams.get('store_id') || ''
    setCurrentStore(store_id)
  }, [])

  const next = (store_id = '') => {
    store_id && setCurrentStore(store_id)
    setTimeout(() => {
      setCurrentStep(currentStep + 1)
    }, 100)
    setSearchParams(
      createSearchParams({
        edit: searchParams.get('edit') || '',
        store_id: store_id || currentStore,
        current_step: (currentStep + 1).toString(),
        service_type: searchParams.get('service_type') || '',
        store_type: searchParams.get('store_type') || '',
        process_date: searchParams?.get('process_date') || '',
      }),
    )
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
    setSearchParams(
      createSearchParams({
        edit: searchParams.get('edit') || '',
        store_id: currentStore,
        current_step: (currentStep - 1).toString(),
        service_type: searchParams.get('service_type') || '',
        store_type: searchParams.get('store_type') || '',
        process_date: searchParams?.get('process_date') || '',
      }),
    )
  }

  const closeHubForm = () => {
    setShowItemCreation(false)
  }

  const nextHandler = (store_id?: string) => {
    next(store_id)
  }
  const previousHandler = () => {
    prev()
  }

  const stepList = [
    {
      title: 'Setup Store',
      description: 'Add Store information',
      content: (
        <TempleCreationStep
          currentStep={currentStep}
          isEditJourney={isEditJourney}
          storeId={currentStore}
          previousHandler={previousHandler}
          nextHandler={nextHandler}
          languageState={languageState}
        />
      ),
    },
    {
      title: 'Offerings',
      description: 'Add items to offers to this store',
      content: (
        <AssigningOfferStep
          currentStep={currentStep}
          isEditJourney={isEditJourney}
          storeId={currentStore}
          previousHandler={previousHandler}
          nextHandler={nextHandler}
          languageState={languageState}
        />
      ),
    },
    {
      title: 'Faq and Review',
      description: 'Publish or save store',
      content: (
        <FaqsAndReviewStep
          currentStep={currentStep}
          isEditJourney={isEditJourney}
          storeId={currentStore}
          previousHandler={previousHandler}
          nextHandler={nextHandler}
          languageState={languageState}
        />
      ),
    },
  ]
  const itemCreateOrUpdateHandler = () => { }
  return (
    <div style={{ padding: '0 40px' }}>
      <AdminBreadcrumb routes={getPageBreadcrumbs()} />
      <CreateTemplePage
        steps={stepList}
        currentStep={currentStep}
        previousHandler={previousHandler}
        nextHandler={nextHandler}
        isEditJourney={isEditJourney}
      />
      <CreateItem
        showItemCreation={showItemCreation}
        closeItemCreation={closeHubForm}
        createItemCallback={itemCreateOrUpdateHandler}
        UpdateItemCallback={itemCreateOrUpdateHandler}
        isEditJourney={isEditJourney}
      />
    </div>
  )
}

export default withDefaultLayout(CreateTempleScreen)
