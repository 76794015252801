import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Pagination, Response } from './types'

class UserReviewApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  getAllReviews = (paginationProps: object, lang: string) =>
    this.api.get<Response<any>>(`/gw2/puja/internal/v1/nps/reviews`, {
      params: paginationProps,
      headers: { 'x-afb-language-mode': lang },
    })

  updateReviewModerated = (
    review_id: string,
    payload: { is_moderated: boolean },
    lang: string,
  ) => {
    this.api.patch<string>(
      `/gw2/puja/internal/v1/nps/reviews/${review_id}`,
      payload,
      { headers: { 'x-afb-language-mode': lang } },
    )
  }
}

export default UserReviewApi
