import React from 'react';
import { Form, Input, Row, Card, Divider, Typography, Select } from 'antd';
import styled from 'styled-components';
import ColorProperties from '../../../components/inputs/ColorProperties';
import { FormInstance } from 'antd/es/form';
import MediaProperties from '../../../components/inputs/MediaProperties';
import { CreateStore } from '@a4b/api/src/modules/Monetisation/Puja/types';

const Block = styled(Card)`
  background: #f5f5f5;
  padding: 5px;

  border-radius: 10px;
  margin-bottom: 10px;
`

const Subtitle = styled(Typography.Title)`
  color: #8c8c8c !important;
`

interface JourneyMetaDataProps {
    formInstance: FormInstance
    storeList: CreateStore[]
}

const JourneyMetaData: React.FC<JourneyMetaDataProps> = ({ formInstance, storeList }) => {
    return (
        <div style={{ marginTop: '15px' }}>
            {/* Icon details */}
            <Form.Item
                label={<Subtitle level={5}>Icon details</Subtitle>}>
                <Block>
                    {/* Prebooking */}
                    <Form.Item
                        label={'Pre booking'}>
                        <ColorProperties formInstance={formInstance} fieldPath={['journey_tag_metadata', 'icon_details', 'pre_booking', 'bg_color']} />
                        <MediaProperties fieldPath={['journey_tag_metadata', 'icon_details', 'pre_booking', 'media']} />
                    </Form.Item>

                    {/* Select BG color */}
                    <Form.Item
                        label={'Select Background color'}>
                        <ColorProperties formInstance={formInstance} fieldPath={['journey_tag_metadata', 'icon_details', 'selected_bg_color']} />
                    </Form.Item>

                    {/* Post booking */}
                    <Form.Item
                        label={'Post booking'}>
                        <ColorProperties formInstance={formInstance} fieldPath={['journey_tag_metadata', 'icon_details', 'post_booking', 'bg_color']} />
                        <MediaProperties fieldPath={['journey_tag_metadata', 'icon_details', 'post_booking', 'media']} />
                    </Form.Item>
                </Block>
            </Form.Item >

            <Divider />

            {/* Card details*/}
            <Form.Item
                label={<Subtitle level={5}>Card details</Subtitle>}>
                <Block>
                    <div style={{ width: '100%' }}>
                        <Form.Item
                            label='Pre booking'>
                            <Block>
                                <Form.Item
                                    label='Description(Hindi)'
                                    name={['journey_tag_metadata', 'card_details', 'pre_booking', 'description']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'Please enter Description' }]}
                                >
                                    <Input placeholder='Enter Description' />
                                </Form.Item>
                                <Form.Item
                                    label='Description(English)'
                                    name={['journey_tag_metadata', 'card_details', 'pre_booking', 'description_en']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'Please enter Description' }]}
                                >
                                    <Input placeholder='Enter Description' />
                                </Form.Item>

                                <Form.Item
                                    label='CTA(Hindi)'
                                    name={['journey_tag_metadata', 'card_details', 'pre_booking', 'cta']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'Please enter CTA' }]}
                                >
                                    <Input placeholder='Enter CTA' />
                                </Form.Item>
                                <Form.Item
                                    label='CTA(English)'
                                    name={['journey_tag_metadata', 'card_details', 'pre_booking', 'cta_en']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'Please enter CTA' }]}
                                >
                                    <Input placeholder='Enter CTA' />
                                </Form.Item>

                                <MediaProperties fieldPath={['journey_tag_metadata', 'card_details', 'pre_booking', 'media']} />
                            </Block>
                        </Form.Item>
                        {/* <div style={{ flexBasis: '48%' }}>
                        </div> */}
                        <Form.Item
                            label='Post booking'>
                            <Block>
                                <Form.Item
                                    label='Description'
                                    name={['journey_tag_metadata', 'card_details', 'post_booking', 'description']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'Please enter Description' }]}
                                >
                                    <Input placeholder='Enter Description' />
                                </Form.Item>
                                <Form.Item
                                    label='Description'
                                    name={['journey_tag_metadata', 'card_details', 'post_booking', 'description_en']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'Please enter Description' }]}
                                >
                                    <Input placeholder='Enter Description' />
                                </Form.Item>

                                <MediaProperties fieldPath={['journey_tag_metadata', 'card_details', 'post_booking', 'media']} />
                            </Block>
                        </Form.Item>
                        {/* <div style={{ flexBasis: '48%' }}>
                        </div> */}
                    </div>
                </Block>
            </Form.Item>

            <Divider />

            {/*Popup Details*/}
            <Form.Item
                label={<Subtitle level={5}>Popup Details</Subtitle>}>
                <Block>
                    <MediaProperties fieldPath={['journey_tag_metadata', 'popup_details', 'media']} />
                </Block>
            </Form.Item>

            <Form.Item
                label={<Subtitle level={5}>Completed subtitle</Subtitle>}>
                <Block>
                    <Form.Item
                        label='Completed subtitle(Hindi)'
                        name={['journey_tag_metadata', 'completed_subtitle']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: 'Please enter Completed subtitle' }]}
                    >
                        <Input placeholder='Enter Completed subtitle' />
                    </Form.Item>
                    <Form.Item
                        label='Completed subtitle(English)'
                        name={['journey_tag_metadata', 'completed_subtitle_en']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: 'Please enter Completed subtitle' }]}
                    >
                        <Input placeholder='Enter Completed subtitle' />
                    </Form.Item>
                </Block>
            </Form.Item>


            {/* Attach stores */}
            <Form.Item
                label={<Subtitle level={5}>Attach store IDs </Subtitle>}>
                <Block>
                    <Form.Item
                        label='Store List'
                        name={['journey_tag_metadata', 'attached_store_ids']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: 'Please enter Completed subtitle' }]}
                    >
                        <Select mode='multiple'>
                            {
                                storeList.map((store) => {
                                    return <Select.Option value={store.id} >{store.title}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Block>
            </Form.Item>
            <div>

            </div>
        </div >

    );
};

export default JourneyMetaData;
