import AdminBreadcrumb from "@/ui/organisms/AdminBreadcrumb";
import RescheduleForm from "../components/form/RescheduleForm";
import withDefaultLayout from "@/hoc/WithDefaultLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "@/components/AppContext";
import { A4B_APPS } from "@/utils/constants";
import { Card, message, Spin, Typography } from "antd";
import { ReschedulePayload } from "@a4b/api/src/modules/Monetisation/Puja/types/reschedule";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { CustomLoader } from "@/components/Loader";
import moment from "moment-timezone";

const Container = styled(Card)`
display: flex;
flex-direction: column;
background:#FAFAFA;

.ant-card-body {
  flex-grow: 1;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}
`
const StyledRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 8px; /* Optional for spacing between rows */
`;

const StyledText = styled(Typography.Text)`
  margin-left: 10px;
`;

const Box = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="2" fill="#B2B2B2" fill-opacity="0.3" />
</svg>

interface InfoRowProps {
    children: React.ReactNode
}

const InfoRow = ({ children }: InfoRowProps) => (
    <StyledRow>
        <Box />
        <StyledText type="secondary">{children}</StyledText>
    </StyledRow>
);

const PujaReschedule: React.FC = () => {
    const { storeId } = useParams()
    const { networkInstance, app, countryState } = useAppContext()
    const navigate = useNavigate()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
    const [store, setStore] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)

    const getPageBreadcrumbs = () => [
        {
            path: '/monetisation/puja/puja-updates',
            breadcrumbName: 'Puja Updates',
        },
        {
            path: '',
            breadcrumbName: 'Puja Reschedule',
        },
    ]

    const getStoreData = useCallback(
        async (id: string) => {
            setLoading(true)
            try {
                const storeResponse = await monetisationPujaApi.storesApi.getStoreById(id, countryState?.countryCode);
                setStore(storeResponse?.data?.data)
            } catch (error) {
                console.error(error)
                message.error('Error while fetching store data', 3)
                return undefined
            } finally {
                setLoading(false)
            }
        },
        [countryState?.countryCode, monetisationPujaApi.storesApi]
    )

    useEffect(() => {
        if (!storeId) {
            message.error('StoreId not found')
            return
        }
        getStoreData(storeId)
    }, [getStoreData, storeId])

    console.log({ store })

    const formSubmitHandler = async (data: ReschedulePayload): Promise<{ success: boolean; message: string }> => {
        console.log('puja reschedule parent component---->', data)
        if (!storeId) {
            message.error('Store ID not found')
            return { success: false, message: 'Store ID not found' };
        }
        try {
            await monetisationPujaApi.rescheduleApi.pujaReschedule(storeId, data)
            message.success({ content: 'Puja Reschedule initiated Successfully', duration: 3 })
            return { success: true, message: 'Puja Reschedule initiated Successfully' };
        } catch (error: any) {
            message.error({ content: error?.response?.data?.error?.message, duration: 3 })
            console.log({ error })
            return { success: false, message: 'Error while Puja Reschedule initiating' };
        }
    }

    return (
        <div style={{ margin: '0 40px' }}>
            <AdminBreadcrumb routes={getPageBreadcrumbs()} />
            <Container>
                {
                    loading ? (
                        <Spin indicator={<CustomLoader />} />
                    ) : (
                        <>
                            <Typography.Title level={5}> {store?.title} </Typography.Title>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <InfoRow>
                                    {store?.location_details?.temple_details?.name}, {store?.location_details?.temple_details?.location}
                                </InfoRow>
                                <InfoRow>
                                    {store?.schedule_date && `${moment(store?.schedule_date)?.format('D MMMM, dddd')} , `} {store?.hindi_calendar_date}
                                </InfoRow>
                            </div>
                        </>
                    )
                }
            </Container>
            {storeId && <RescheduleForm formType="pooja" existingStoreId={storeId} formSubmitHandler={formSubmitHandler} successCallBack={() => navigate('/monetisation/puja/puja-updates')} />}
        </div>
    )
}

export default withDefaultLayout(PujaReschedule)