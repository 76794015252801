import { useAppContext } from '@/components/AppContext';
import withDefaultLayout from '@/hoc/WithDefaultLayout';
import { A4B_APPS } from '@/utils/constants';
import { Table, Checkbox, message, Card, Modal, Button } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`;

interface Review {
  id: string;
  date: string;
  language: string;
  rating: number;
  suggestion: string;
  is_moderated: boolean;
}

const ManageReviews = () => {
  const { networkInstance, app, languageState } = useAppContext();
  const [tableDataService, setTableDataService] = useState<Review[]>([]);
  const [showUpdateReviewModeratedModal, setShowUpdateReviewModeratedModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 30, isLastPage: false });

  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR
    ? networkInstance.clientWithHeaders.monetisationPujaApi
    : networkInstance.clientWithHeaders.monetisationAstroPujaApi;

  const getAllReviews = useCallback(async (currentPage = 1) => {
    try {
      const reviewsResponse = await monetisationPujaApi?.userReviewsApi?.getAllReviews(
        { page_no: currentPage },
        languageState?.monetisationLanguage
      );

      if (reviewsResponse) {
        const reviews: Review[] = reviewsResponse?.data?.data?.reviews;
        const isLastPage = reviewsResponse?.data?.data?.is_last_page;

        // Append new reviews to existing data to maintain current data
        setTableDataService(reviews);
        setPagination((prev) => ({ ...prev, isLastPage }));
      }
    } catch (error) {
      message.error('Failed to fetch reviews.');
    }
  }, [languageState?.monetisationLanguage, monetisationPujaApi]);

  useEffect(() => {
    getAllReviews(pagination.current); // Fetch reviews when the page number changes
  }, [getAllReviews, pagination?.current, languageState?.monetisationLanguage]);

  const columns = [
    { title: 'Review Id', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Language', dataIndex: 'language', key: 'language' },
    { title: 'Star Rating', dataIndex: 'rating', key: 'rating' },
    {
      title: 'Additional comment',
      dataIndex: 'suggestion',
      key: 'suggestion',
      width: 500, // Set a fixed width for the column
      render: (text: any) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: 'Moderated',
      dataIndex: 'moderated',
      key: 'moderated',
      render: (text: any, record: Review) => (
        <Checkbox
          checked={record?.is_moderated}
          onChange={() => {
            setSelectedReview(record);
            setShowUpdateReviewModeratedModal(true);
          }}        />
      ),
    },
  ];

  const handleModeratedChange = async (record: Review) => {
    try {
      const updatedStatus = !record.is_moderated;
      await monetisationPujaApi?.userReviewsApi?.updateReviewModerated(
        record?.id,
        { is_moderated: updatedStatus },
        languageState?.monetisationLanguage
      );

      setTableDataService((prevReviews) =>
        prevReviews.map((review) =>
          review.id === record.id
            ? { ...review, is_moderated: updatedStatus }
            : review
        )
      );
    } catch (error) {
      message.error('Failed to update moderated status.');
    }
  };
  const setPageChange = (page: number, pageSize: number) => {
    setPagination((prev) => ({ ...prev, current: page, pageSize }));
  };
  const handleTableChange = (value: 'previous' | 'next') => {
    if (value === 'previous' && pagination?.current > 1) {
      setPageChange(pagination?.current - 1, pagination?.pageSize);
    } else if (value === 'next' && !pagination?.isLastPage) {
      setPageChange(pagination?.current + 1, pagination?.pageSize);
    }
  };

  const onModalOk = () => {
    if (selectedReview) {
      handleModeratedChange(selectedReview);
    }
    setShowUpdateReviewModeratedModal(false);
  };

  const onModalCancel = () => {
    setShowUpdateReviewModeratedModal(false);
  };

  return (
    <div style={{ padding: '40px' }}>
      <Container style={{ width: '100%' }}>
        <Table
          dataSource={tableDataService}
          columns={columns}
          size="large"
          pagination={false}
        />
        {pagination?.current &&
          <div style={{ display: 'flex', justifyContent: 'right', width: '100%', paddingTop: '10px' }}>
            <Button onClick={() => handleTableChange('previous')} disabled={pagination?.current === 1}>Prev</Button>
            <Button style={{ marginLeft: '5px', marginRight: '5px' }}>{String(pagination?.current)}</Button>
            <Button onClick={() => handleTableChange('next')} disabled={pagination?.isLastPage}>Next</Button>
          </div>
        }
      </Container>
      <Modal
        title="Update Moderation Status"
        visible={showUpdateReviewModeratedModal}
        onOk={onModalOk}
        onCancel={onModalCancel}
        okText="Yes, Update"
      >
        <p>Are you sure you want to update the moderation status?</p>
      </Modal>
    </div>
  );
};

export default withDefaultLayout(ManageReviews);
