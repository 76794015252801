import { Badge, Button, Col, Row, Tag, Typography } from 'antd'
import styled from 'styled-components'

import React from 'react'
import { Link } from 'react-router-dom'

import { Store } from '@a4b/api/src/modules/Monetisation/offerings/types'

const { Title } = Typography
interface Props {
  data: Store
  link: string
}
const Container = styled.div`
  padding: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  .ant-card-body {
    flex-grow: 1;
  }
  flex-direction: row;
  &:hover {
    cursor: pointer;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`
const ContentWrapper = styled(Title)`
  margin-bottom: 0 !important;
  margin-top: 0;
`
const SubTitleWrapper = styled.span`
  color: #434343;
`
const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`
const Anchor = styled(Link)`
  margin-bottom: 20px;
  display: block;
`
const TempleListItemInfo: React.FC<Props> = ({
  data: { name, total_new_orders, total_processing_orders, city, store_type, service_type, total_active_batches },
  link,
}) => {
  const RowContaier = <Container>
    <Row
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      style={{ width: '100%' }}
    >
      <Col className='gutter-row' span={8}>
        <InfoWrapper>
          <ContentWrapper level={5}>{name}</ContentWrapper>

          {<SubTitleWrapper>{city}</SubTitleWrapper>}
        </InfoWrapper>
      </Col>
      <Col className='gutter-row' span={3}>
        <InfoWrapper>
          <ContentWrapper level={5}>{total_new_orders}</ContentWrapper>
          <SubTitleWrapper>New orders</SubTitleWrapper>
        </InfoWrapper>
      </Col>
      <Col className='gutter-row' span={3}>
        <InfoWrapper>
          <ContentWrapper level={5}>
            {total_processing_orders}
          </ContentWrapper>
          <SubTitleWrapper>Processing</SubTitleWrapper>
        </InfoWrapper>
      </Col>
      <Col className='gutter-row' span={3}>
        <InfoWrapper>
          <ContentWrapper level={5}>
            {total_active_batches}
          </ContentWrapper>
          <SubTitleWrapper>Active Batches</SubTitleWrapper>
        </InfoWrapper>
      </Col>
      <Col className='gutter-row' span={2}>
        <InfoWrapper>
          <ContentWrapper level={5}>
            &nbsp;
          </ContentWrapper>
          <SubTitleWrapper>
            <Tag color='blue'>  {service_type}</Tag>
          </SubTitleWrapper>
        </InfoWrapper>
      </Col>
      <Col className='gutter-row' span={4}>
        <LabelRightAlign>
          <Button type='text'> &rarr;</Button>
        </LabelRightAlign>
      </Col>
    </Row>
  </Container>
  return (
    <Anchor to={link} key={link}>
      {store_type === "mixed" ? <Badge.Ribbon color='orange' text="Mixed Store" >
        {RowContaier}
      </Badge.Ribbon> : RowContaier}
    </Anchor>
  )
}
export default TempleListItemInfo
