import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Response } from './types'
import { ReschedulePayload, RescheduledDetailsData } from './types/reschedule'

class RescheduleApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  orderReschedule = (orderId: string, payload: ReschedulePayload) => {
    return this.api.post<Response<unknown>>(
      `/gw2/puja/internal/v1/orders/${orderId}/reschedule`,
      payload,
    )
  }
  pujaReschedule = (storeId: string, payload: ReschedulePayload) => {
    return this.api.post<Response<unknown>>(
      `/gw2/puja/internal/v1/stores/${storeId}/reschedule`,
      payload,
    )
  }
  orderRescheduledDetails = (orderId: string) => {
    return this.api.get<Response<RescheduledDetailsData>>(
      `/gw2/puja/internal/v1/orders/${orderId}/reschedule`,
    )
  }
  pujaRescheduledDetails = (storeId: string) => {
    return this.api.get<Response<RescheduledDetailsData>>(
      `/gw2/puja/internal/v1/stores/${storeId}/reschedule`,
    )
  }
  updateOrderState = (orderId: string, payload: { state: string }) => {
    return this.api.put<Response<unknown>>(
      `/gw2/puja/internal/v1/orders/${orderId}/update_state`,
      payload,
    )
  }
}

export default RescheduleApi
