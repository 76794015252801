import React from 'react'
import { Drawer, message, Row, Tag } from 'antd'
import RescheduleForm from '../../../components/form/RescheduleForm'
import { ReschedulePayload } from '@a4b/api/src/modules/Monetisation/Puja/types/reschedule'
import { useAppContext } from '@/components/AppContext'
import { A4B_APPS } from '@/utils/constants'

interface Props {
    showOrderRescheduleForm: boolean
    closeOrderRescheduleForm: (isRescheduled?: boolean) => void
    initialValues: any
}

const OrderRescheduleDetailsForm: React.FC<Props> = ({
    showOrderRescheduleForm,
    closeOrderRescheduleForm,
    initialValues,
}) => {
    const { networkInstance, app } = useAppContext()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi

    const totalInventories = [...initialValues?.items ?? [], ...initialValues?.add_ons ?? []]
    const parsedInventory = totalInventories?.map((item: any) => {
        const countryCode = item?.country_code === 'IN' ? 'India' : 'Global'
        return {
            label: `${item?.name} + ${countryCode} + ${item?.id}`,
            value: item?.id,
            itemType: item?.type,
            countryCode: item?.country_code
        }
    }
    )

    const formSubmitHandler = async (data: ReschedulePayload): Promise<{ success: boolean; message: string }> => {
        console.log('order reschedule parent component---->', data);
        if (!initialValues?.id) {
            message.error('Order ID not found');
            return { success: false, message: 'Order ID not found' };
        }

        try {
            await monetisationPujaApi.rescheduleApi.orderReschedule(initialValues.id, data);
            message.success({ content: 'Order Reschedule initiated Successfully', duration: 3 });
            return { success: true, message: 'Order Reschedule initiated Successfully' };
        } catch (error: any) {
            message.error({ content: error?.response?.data?.error?.message, duration: 3 });
            console.log({ error });
            return { success: false, message: 'Error while order Reschedule initiating' };
        }
    };

    return (
        <>
            <Drawer
                title={
                    <Row justify='space-between'>
                        <span>Order Reschedule</span>
                        <div>
                            Order ID: <Tag color='blue'>{initialValues?.id}</Tag>
                        </div>
                    </Row>}
                placement='right'
                onClose={() => closeOrderRescheduleForm()}
                visible={showOrderRescheduleForm}
                destroyOnClose={true}
                size='large'
                width={1200}
            >
                <RescheduleForm
                    formType="order"
                    existingStoreId={initialValues?.store_id}
                    orderInventories={parsedInventory}
                    formSubmitHandler={formSubmitHandler}
                    successCallBack={closeOrderRescheduleForm}
                />
            </Drawer >
        </>
    )
}

export default OrderRescheduleDetailsForm
