import { Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
const { Title } = Typography
interface Props {
  orderCount: number
  processingCount: number
  activeBatchesCount: number
}
const Container = styled.div`
  padding: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  .ant-card-body {
    flex-grow: 1;
  }
  flex-direction: row;
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 130px;
  h3 {
    margin-bottom: 0;
  }
`
const ContentWrapper = styled(Title)`
  margin-bottom: 0;
  margin-top: 0;
`
const OrderInfoContainer: React.FC<Props> = ({
  orderCount,
  processingCount,
  activeBatchesCount
}) => {
  return (
    <Container>
      <InfoWrapper>
        <ContentWrapper level={3}>{orderCount}</ContentWrapper>
        <span>New Orders</span>
      </InfoWrapper>
      <InfoWrapper>
        <ContentWrapper level={3}>{processingCount}</ContentWrapper>
        <span>Processing</span>
      </InfoWrapper>
      <InfoWrapper>
        <ContentWrapper level={3}>{activeBatchesCount}</ContentWrapper>
        <span>Active Batches</span>
      </InfoWrapper>
    </Container>
  )
}
export default OrderInfoContainer
