import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { Button, Col, Form, Row, Select, Spin, message } from 'antd'
import { useAppContext } from '@/components/AppContext'
import { Category } from '@a4b/api/src/modules/Monetisation/Puja/types/categories'
import { CustomLoader } from '@/components/Loader'
import { A4B_APPS } from '@/utils/constants'

const { Option } = Select

interface FilterFormProps {
    storeInfo: {
        storeId: string
        storeName: string
    }
}

const FilterForm: React.FC<FilterFormProps> = ({ storeInfo }) => {
    const { networkInstance, app } = useAppContext()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
    const [formFilter] = useForm()

    const [categories, setCategories] = useState<Category[]>([])

    const [loadingStates, setLoadingStates] = useState<any>(null)
    const [filterOptions, setFilterOptions] = useState<any>({})

    const [pujaFilters, setPujaFilters] = useState<any>()
    const [initialValuesFilterForm, setInitialValuesFilterForm] = useState<any>()

    const fetchCategoryList = useCallback(async () => {
        try {
            const categoriesResponse = await monetisationPujaApi.categoriesApi.getCategories()
            setCategories(categoriesResponse?.data?.data?.categories)
        } catch (error) {
            message.warning('Error while fetching hub list or categories', 3)
            console.log(error)
        }
    }, [monetisationPujaApi.categoriesApi])

    useEffect(() => {
        fetchCategoryList()
    }, [fetchCategoryList])

    const getFiltersByCategory = useCallback(async (categoryId: string) => {
        setLoadingStates((prev: any) => ({ ...prev, [categoryId]: true }))
        try {
            if (categoryId) {
                const filterList = await monetisationPujaApi.filtersApi.getFilters(categoryId)
                setFilterOptions((prev: any) => ({ ...prev, [categoryId]: filterList?.data?.data?.filters }))
            }
        } catch (error) {
            message.warning('Error while fetching Filters')
            console.log(error)
        } finally {
            setLoadingStates((prev: any) => ({ ...prev, [categoryId]: false }))
        }
    }, [monetisationPujaApi.filtersApi])

    useEffect(() => {
        categories?.map((category) => getFiltersByCategory(category?.id))
    }, [categories, getFiltersByCategory])

    const getPujaFilters = useCallback(async () => {
        try {
            if (storeInfo?.storeId) {
                const filterList = await monetisationPujaApi.pujaFiltersApi.getPujaFilters(storeInfo?.storeId)
                setPujaFilters(filterList?.data?.data)
            }
        } catch (error) {
            message.warning('Error while fetching Puja Filters')
            console.log(error)
        }
    }, [monetisationPujaApi.pujaFiltersApi, storeInfo?.storeId])

    useEffect(() => {
        getPujaFilters()
    }, [getPujaFilters])


    useEffect(() => {
        if (pujaFilters && pujaFilters?.length > 0 && filterOptions && Object.keys(filterOptions)?.length > 0) {
            const initialValues = pujaFilters?.reduce((acc: any, item: any) => {
                if (!acc[item?.category_id]) {
                    acc[item?.category_id] = []
                }
                acc[item?.category_id].push({ filter_id: item?.filter_id, id: item?.id })
                return acc
            }, {})

            const formattedInitialValues = Object.keys(initialValues)?.reduce((acc: any, key: any) => {
                acc[key] = initialValues[key]?.map((item: any) => {
                    const filter = filterOptions[key]?.find((filt: any) => filt?.id === item?.id)
                    return { label: filter?.name, value: item?.filter_id, id: item?.id }
                })
                return acc
            }, {})

            formFilter.setFieldsValue(formattedInitialValues)
            setInitialValuesFilterForm(formattedInitialValues)
        }
    }, [filterOptions, formFilter, pujaFilters])

    const updatePujaFilters = async (categoryId: string, filters: any, type?: string) => {
        const existingFilterIds: any = pujaFilters
            ? new Set(pujaFilters.map((item: any) => item.filter_id))
            : new Set()  // Initialize with an empty set if pujaFilters is null

        const newFilterIds = filters
            ?.filter((item: any) => !existingFilterIds.has(item.value))
            ?.map((item: any) => item.value)


        const payload = {
            puja_id: storeInfo?.storeId,
            filter_ids: newFilterIds,
            category_id: categoryId
        }

        try {
            await monetisationPujaApi.pujaFiltersApi.createPujaFilter(payload)
            message.success('Updated puja filter', 1)
        } catch (e) {
            message.error('Error while updating puja filter', 1)
            console.log(e)
        }
        // else {
        //     try {
        //         await monetisationPujaApi.pujaFiltersApi.updatePujaFilter(payload)
        //     }
        //     catch (e) { console.log(e) }
        // }
    }

    const handleClearFilter = async (categoryId: string, selectedItem: any) => {
        const matchedFilter = pujaFilters?.find((filter: any) =>
            filter?.filter_id === selectedItem?.value && filter?.category_id === categoryId
        )
        if (matchedFilter) {
            const idToDelete = matchedFilter?.id;
            try {
                await monetisationPujaApi.pujaFiltersApi.deletePujaFilter(idToDelete);
                message.success('Deleted puja filter', 2);
            } catch (e) {
                message.error('Error while deleting puja filter', 2);
                console.log(e);
            }
        } else {
            console.log('No matching filter found for deletion');
        }
    }

    const handleUpdateClick = (categoryId: string) => {
        const type = 'create'
        formFilter
            .validateFields()
            .then((values: any) => {
                console.log(values)
                updatePujaFilters(categoryId, values[categoryId], type)
            })
            .catch(() => {
                console.log('Temple Creation error')
            })
    }

    const handleSelectChange = (categoryId: any, selectedItems: any) => {
        formFilter?.setFieldValue(categoryId, selectedItems)
    }

    const isFiltersLoaded = loadingStates && Object.values(loadingStates)?.every?.(value => value !== false)

    return (
        <Form
            form={formFilter}
            initialValues={initialValuesFilterForm}
            onFinish={handleUpdateClick}
            layout="vertical"
            style={{ width: '100%' }}
        >
            {
                isFiltersLoaded ?
                    <Row style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin indicator={<CustomLoader />} />
                    </Row> :
                    categories?.length > 0 ?
                        <>
                            <span style={{ fontSize: '16px', fontWeight: '500' }}>Filters:</span>
                            <div style={{ width: '100%', marginTop: '10px', border: '1px dashed gray', padding: '16px' }}>
                                {categories?.map((category) => (
                                    <div key={category?.id} style={{ width: '100%', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                        <Col span={4} style={{ marginBottom: '10px' }}>
                                            <span style={{ fontWeight: 'bold' }}>{category?.name}</span>
                                        </Col>
                                        <Col span={14} style={{ marginRight: '10px' }}>
                                            <Form.Item
                                                name={category?.id}
                                                key={category?.id}
                                                style={{ width: '100%', marginBottom: 0 }}
                                            >
                                                <Select
                                                    placeholder="Select filters..."
                                                    loading={loadingStates?.[category?.id]}
                                                    mode="multiple"
                                                    onChange={(selectedItems) => handleSelectChange(category?.id, selectedItems)}
                                                    onDeselect={(selectedItems: string) => handleClearFilter(category?.id, selectedItems)}
                                                    labelInValue
                                                    style={{ width: '100%' }}
                                                    value={initialValuesFilterForm?.[category?.id] || []}
                                                >
                                                    {filterOptions?.[category?.id]?.map((item: any) => (
                                                        <Option value={item?.id} key={item?.id}>
                                                            {item?.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4} style={{}}>
                                            <Button
                                                type="primary"
                                                htmlType="button"
                                                onClick={() => handleUpdateClick(category?.id)}
                                                loading={loadingStates?.[category?.id]}
                                                style={{ width: '100%' }}
                                            >
                                                {'Update filter'}
                                            </Button>
                                        </Col>
                                    </div>
                                ))}
                            </div>
                        </> :
                        <></>
            }
        </Form>
    )
}

export default FilterForm