import React from 'react'
import { SelectOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Form, Input, Row, Select, Tag } from 'antd'
import { CreateStore, StoreCard } from '@a4b/api/src/modules/Monetisation/Puja/types'
import InfoMedia from './InfoMedia'
import PreviousPoojaMedia from './PreviousPoojaMedia'
import BannerItems from './BannerItems'
import TodayImportance from './TodayImportance'
import SrimandirService from './SrimandirService'
import GlimpsesOfServices from './GlimpsesOfServices'
import AssociatedTemples from './AssociatedTemples'
import PreviousOrderRecommendations from './PreviousOrderRecommendations'
import BannerLarge from './BannerLarge'
import SanakalpBanner from './SankalpBanner'
import HighlightedPooja from './HighlightedPooja'
import { formatJson } from '@/utils/helper'
import InfoMediaWeb from './InfoMediaWeb'

const { TextArea } = Input
const { Option } = Select
interface Props {
  onClose: () => void
  visible: boolean
  cdnUrl: string
  preSignUrl: string
  formSubmithandler: (values: any) => void
  form: any
  initialValues: StoreCard
  isEditJourney: boolean
  storeList: CreateStore[]
  onFormChange: (formValues: any) => void
  openSelectionWindow: () => void
  sortedData: any[]
  componentRender: any
  getInventoryList: (storeId: string) => void
  inventoryList: any
}
const dataTypes = [
  { value: 'info_media', label: 'Info media' },
  { value: 'info_media_web', label: 'Info media web' },
  { value: 'previous_pooja_media', label: 'Previous pooja media' },
  { value: 'banner_items', label: 'Banner items' },
  { value: 'today_importance', label: 'Today Importance' },
  { value: 'srimandir_services', label: 'Srimandir Service' },
  { value: 'glimpses_of_services', label: 'Glimpses of services' },
  { value: 'associated_temples', label: 'Associated temples' },
  { value: 'associated_pilgrims', label: 'Associated pilgrims' },
  { value: 'previous_order_recommendation_card', label: 'Previous order recommendation' },
  { value: 'banner_large', label: 'Banner Large' },
  { value: 'sankalp_banner', label: 'Sankalp banner' },
  { value: 'highlighted_pooja', label: 'Highlighted Pooja' },
  { value: 'declarative', label: 'Declarative' },
]

const sectionTypes = [
  { value: 'hero', label: 'Hero' },
  { value: 'category', label: 'Category' },
  { value: 'data', label: 'Data' },
  { value: 'list_item_small', label: 'List_Item_Small' },
  { value: 'list_item_large', label: 'List_Item_Large' },
  { value: 'declarative', label: 'Declarative' },
]

type ComponentType =
  | 'info_media'
  | 'info_media_web'
  | 'previous_pooja_media'
  | 'banner_items'
  | 'today_importance'
  | 'srimandir_services'
  | 'glimpses_of_services'
  | 'associated_temples'
  | 'associated_pilgrims'
  | 'previous_order_recommendation_card'
  | 'banner_large'
  | 'highlighted_pooja'
  | 'sankalp_banner'

interface RenderComponentProps {
  cdnUrl: string
  preSignUrl: string
  storeList: any[]
  getInventoryList?: (storeId: string) => void
  inventoryList: any[]
  form: any
}

const SectionCreation: React.FC<Props> = ({
  onClose,
  visible,
  cdnUrl,
  preSignUrl,
  formSubmithandler,
  form,
  initialValues,
  isEditJourney,
  storeList,
  onFormChange,
  openSelectionWindow,
  componentRender,
  sortedData,
  getInventoryList,
  inventoryList
}) => {
  const renderSectionComponent = (type: ComponentType, props: RenderComponentProps) => {
    switch (type) {
      case 'info_media':
        return (
          <Form.List name={['data', 'info_media']}>
            {(fields, { add, remove }) => (
              <InfoMedia fields={fields} add={add} remove={remove} cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} type={'info_media'} />
            )}
          </Form.List>
        )
      case 'info_media_web':
        return (
          <InfoMediaWeb cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} type={'info_media_web'} />
        )
      case 'previous_pooja_media':
        return (
          <Form.List name={['data', 'media_cards']}>
            {(fields, { add, remove }) => (
              <PreviousPoojaMedia fields={fields} add={add} remove={remove} cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} />
            )}
          </Form.List>
        )
      case 'banner_items':
        return (
          <>
            <Form.List name={['data', 'pooja_items']}>
              {(fields, { add, remove }) => (
                <BannerItems fields={fields} add={add} remove={remove} cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} type='Add Pooja Items' />
              )}
            </Form.List>
            <Form.List name={['data', 'offering_items']}>
              {(fields, { add, remove }) => (
                <BannerItems fields={fields} add={add} remove={remove} cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} type='Add Offering Items' />
              )}
            </Form.List>
          </>
        )
      case 'today_importance':
        return <TodayImportance storeList={props.storeList} cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} />
      case 'srimandir_services':
        return (
          <Form.List name={['data', 'srimandir_services']}>
            {(fields, { add, remove }) => (
              <SrimandirService fields={fields} add={add} remove={remove} cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} />
            )}
          </Form.List>
        )
      case 'glimpses_of_services':
        return (
          <Form.List name={['data', 'media_cards']}>
            {(fields, { add, remove }) => (
              <GlimpsesOfServices fields={fields} add={add} remove={remove} cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} datatype='Glimpses of services' />
            )}
          </Form.List>
        )
      case 'associated_temples':
      case 'associated_pilgrims':
        return (
          <Form.List name={['data', 'media_cards']}>
            {(fields, { add, remove }) => (
              <AssociatedTemples fields={fields} add={add} remove={remove} cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} datatype={type === 'associated_temples' ? 'Associated temples' : 'Associated pilgrims'} />
            )}
          </Form.List>
        )
      case 'previous_order_recommendation_card':
        return (
          <Form.List name={['data', 'banner_large_items']}>
            {(fields) => (
              <PreviousOrderRecommendations fields={fields} cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} />
            )}
          </Form.List>
        )
      case 'banner_large':
        return (
          <Form.List name={['data', 'banner_large']}>
            {(fields, { add, remove }) => (
              <BannerLarge fields={fields} add={add} remove={remove} cdnUrl={props.cdnUrl} preSignUrl={props.preSignUrl} datatype='Banner Large' />
            )}
          </Form.List>
        )
      case 'highlighted_pooja':
        return (
          <Form.List name={['data', 'highlighted_pooja']}>
            {(fields, { add, remove, move }) => (
              <HighlightedPooja
                onSortEnd={({ oldIndex, newIndex }) => {
                  console.log('oldIndex', oldIndex, 'newIndex', newIndex)
                  move(oldIndex, newIndex)
                }}
                distance={1}
                helperClass='sortableHelper'
                form={props.form}
                fields={fields}
                add={add}
                remove={remove}
                cdnUrl={props.cdnUrl}
                preSignUrl={props.preSignUrl}
                datatype='Highlighted Pooja'
              />
            )}
          </Form.List>
        )
      case 'sankalp_banner':
        return (
          <Form.List name={['data', 'sankalp_banner_items']}>
            {(fields, { add, remove }) => (
              <SanakalpBanner fields={fields} add={add} remove={remove} storeList={props.storeList} getInventoryList={props.getInventoryList} inventoryList={props.inventoryList} />
            )}
          </Form.List>
        )
      default:
        return null
    }
  }

  return (
    <Drawer
      title={`${isEditJourney ? "Update" : "Create New"}  Section`}
      placement='right'
      onClose={onClose}
      visible={visible}
      destroyOnClose={true}
      width={900}
      size='large'
    >
      <Form
        form={form}
        layout='vertical'
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        initialValues={initialValues}
        onValuesChange={onFormChange}
      >
        <Row>
          <Col span={10}>
            <Form.Item
              label='Title'
              name='title'
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              rules={[{ required: true, message: 'Enter title' }]}
            >
              <Input placeholder='Enter title' />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label='Description'
              name='description'
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 22 }}
              rules={[
                {
                  required: true,
                  message: 'Enter description of the category',
                },
              ]}
            >
              <TextArea placeholder='Enter description' rows={3} />
            </Form.Item>
          </Col>
          <Row style={{ width: '100%' }}>
            <Col span={10}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => true
                }
              >
                {({ getFieldValue, setFieldsValue }) => (
                  <>
                    <Form.Item
                      labelCol={{ span: 22 }}
                      wrapperCol={{ span: 22 }}
                      name="target_config"
                      label={<Row justify='space-between'>
                        <span>Target Config</span>
                        <Tag
                          onClick={() => {
                            const prettyJson = formatJson(getFieldValue("target_config"))
                            setFieldsValue({ "target_config": prettyJson })
                          }}
                          style={{
                            cursor: 'pointer',
                            marginLeft: '20px',
                            zIndex: 1000
                          }}
                          color="blue"
                        >Pretty</Tag></Row>}
                      rules={[
                        {
                          required: false,
                          message: 'Please Enter Target Config',
                        },
                        {
                          validator: async (rules: any, values: any) => {
                            const targetConfig = getFieldValue("target_config")
                            try {
                              if (targetConfig)
                                JSON.parse(targetConfig)
                            } catch (e) {
                              throw new Error(`${String(e)}`)
                            }
                          },
                        }
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </>
                )}
              </Form.Item>
            </Col>
            <Col span={13} style={{ width: '100%' }}>
              <Row justify='space-between' style={{ width: '100%' }}>
                <Form.Item
                  label={`Position`}
                  name='position'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message: 'Please input position!' }]}
                  style={{ flexBasis: '18%' }}
                >
                  <Input type={'number'} placeholder={`position`} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  label={`Min app version`}
                  name='min_app_version'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false }]}
                  style={{ flexBasis: '25%', marginLeft: '10px' }}
                >
                  <Input type={'number'} placeholder={`Min app version`} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  label={`Variant`}
                  name='variant'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false }]}
                  style={{ flexBasis: '50%', marginLeft: '15px' }}
                >
                  <Input placeholder={`Min app Ver`} style={{ width: '100%' }} />
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <Row style={{ width: '100%' }} justify='space-between'>
            <Col span={8}>
              <Form.Item
                label='Section Type'
                name='section_type'
                rules={[
                  { required: true, message: 'Please select section type!' },
                ]}
              >
                <Select placeholder='Please select section type' disabled={isEditJourney}>
                  {sectionTypes.map(item => (
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  return prevValues.section_type !== currentValues.section_type
                }
                }
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 22 }}
              // style={{ width: '100%' }}
              >
                {({ getFieldValue }) => (
                  <Form.Item
                    label='Item Type'
                    name='item_type'
                    style={{ width: '100%' }}
                    rules={[
                      { required: true, message: 'Please select item type!' },
                    ]}
                  >
                    {
                      getFieldValue('section_type') === 'declarative' ?
                        <Select placeholder='Select item type' allowClear style={{ width: '100%' }}>
                          <Option value={'top_categories'} key={'top_categories'}>
                            Top categories
                          </Option>
                          <Option value={'journey_card'} key={'journey_card'}>
                            Journey card
                          </Option>
                          <Option value={'filters'} key={'filters'}>
                            Filters
                          </Option>
                        </Select>
                        :
                        <Select placeholder='Please select item type' allowClear disabled={getFieldValue('section_type') === 'data' || isEditJourney}>
                          <Option value={'sku'} key={'sku'}>
                            SKU
                          </Option>
                          <Option value={'store'} key={'store'}>
                            Store
                          </Option>
                        </Select>
                    }
                  </Form.Item>
                )}
              </Form.Item>
            </Col>

            <Col span={9}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.section_type !== currentValues.section_type
                }
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 22 }}
              >
                {({ getFieldValue }) =>
                  (getFieldValue('section_type') === 'data' || getFieldValue('section_type') === 'declarative') && (
                    <Col span={12}>
                      <Form.Item
                        label='Data type'
                        name={['data', 'type']}
                        rules={[{ required: true, message: 'Enter Data type' }]}
                        labelCol={{ span: 22 }}
                        wrapperCol={{ span: 22 }}
                      >
                        <Select placeholder='Please Data type' style={{ maxHeight: '200px', overflowY: 'auto', width: '183%' }}>
                          {dataTypes.map(item => (
                            <Option key={item.value} value={item.value}>{item.label}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.section_type !== currentValues.section_type}
          labelCol={{ span: 22 }}
          wrapperCol={{ span: 22 }}
        >
          {({ getFieldValue }) => (
            getFieldValue('section_type') === 'data' && (
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues?.data?.type !== currentValues?.data?.type}
                labelCol={{ span: 22 }}
                wrapperCol={{ span: 22 }}
              >
                {({ getFieldValue }) => renderSectionComponent(getFieldValue('data')?.type, {
                  cdnUrl,
                  preSignUrl,
                  storeList,
                  ...(getFieldValue('section_type') === 'sankalp_banner_items' && {
                    getInventoryList
                  }),
                  inventoryList,
                  form
                })}
              </Form.Item>
            )
          )}
        </Form.Item>

        <Col span={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.item_type !== currentValues.item_type ||
              prevValues.section_type !== currentValues.section_type
            }
            labelCol={{ span: 22 }}
            wrapperCol={{ span: 22 }}
          >
            {({ getFieldValue }) => {
              return (getFieldValue('section_type') === 'hero' || getFieldValue('section_type') === 'list_item_small' || getFieldValue('section_type') === 'list_item_large') &&
                getFieldValue('item_type') === 'store' && (
                  <Button
                    onClick={openSelectionWindow}
                    style={{ marginTop: '20px' }}
                    type='primary'
                    icon={<SelectOutlined />}
                  >
                    Select Store
                  </Button>
                )
            }
            }
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.item_type !== currentValues.item_type ||
              prevValues.section_type !== currentValues.section_type
            }
            labelCol={{ span: 22 }}
            wrapperCol={{ span: 22 }}
          >
            {({ getFieldValue }) =>
              (getFieldValue('section_type') === 'hero' || getFieldValue('section_type') === 'list_item_small' || getFieldValue('section_type') === 'list_item_large') &&
              getFieldValue('item_type') == 'sku' && (
                <Button
                  onClick={openSelectionWindow}
                  style={{ marginTop: '20px' }}
                  type='primary'
                  icon={<SelectOutlined />}
                >
                  Select Store Card
                </Button>
              )
            }
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.section_type !== currentValues.section_type
            }
            labelCol={{ span: 22 }}
            wrapperCol={{ span: 22 }}
          >
            {({ getFieldValue }) =>
              getFieldValue('section_type') === 'category' && (
                <Button
                  onClick={openSelectionWindow}
                  style={{ marginTop: '20px' }}
                  type='primary'
                  icon={<SelectOutlined />}
                >
                  Select Category
                </Button>
              )
            }
          </Form.Item>
        </Col>



        {sortedData?.length > 0 && <Form.Item
          labelCol={{ span: 22 }}
          wrapperCol={{ span: 22 }}
        >
          <Row>
            <Col span={6}></Col>
            <Col span={18} style={{ height: '300px', overflow: 'scroll' }}>
              {sortedData?.map(item => (
                <Col key={item?.id} span={24}>
                  {React.cloneElement(componentRender, { data: item })}
                </Col>
              ))}
            </Col>
          </Row>
        </Form.Item>}


        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ paddingTop: '30px' }}
        >
          {isEditJourney ? (
            <Button type='primary' onClick={formSubmithandler}>
              Update Item
            </Button>
          ) : (
            <Button type='primary' onClick={formSubmithandler}>
              Create Item
            </Button>
          )}
        </Form.Item>
      </Form>
    </Drawer >
  )
}
export default React.memo(SectionCreation)
