import React from 'react';
import { Col, Row, Select } from 'antd';
import usePujaStore from '@/modules/monetisation/puja/stores/puja-store';

const StoreFilter: React.FC = () => {
    const { experiment, setExperiment, variant, setVariant } = usePujaStore()
    return (
        <Row justify='space-between' style={{ width: "100%" }}>
            <span>Create Store</span>
            <Col>
                <label>Experiment</label>
                <Select placeholder="Select experiment" allowClear value={experiment} onChange={(value) => { setExperiment(value) }} style={{ width: '200px', paddingLeft: '10px' }}>
                    <Select.Option value="pooja_store_content_v1">pooja_store_content_v1</Select.Option>
                    <Select.Option value="puja_l2_creatives_80_20">puja_l2_creatives_80_20</Select.Option>
                    <Select.Option value="puja_l2_creatives_95_5">puja_l2_creatives_95_5</Select.Option>
                    <Select.Option value="puja_l2_description_80_20">puja_l2_description_80_20</Select.Option>
                    <Select.Option value="puja_l2_specificity_80_20">puja_l2_specificity_80_20</Select.Option>
                </Select>
                <label style={{ paddingLeft: '30px' }}>Variant</label>
                <Select disabled={!experiment} placeholder="Select variant" allowClear value={variant} onChange={(value) => { setVariant(value) }} style={{ width: '200px', paddingLeft: '10px' }}>
                    <Select.Option value="CONTROL" disabled>CONTROL</Select.Option>
                    <Select.Option value="VARIANT">VARIANT</Select.Option>
                </Select>
            </Col>
        </Row>
    )
}

export default React.memo(StoreFilter);