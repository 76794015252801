import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import { Response, Step, Steps } from './types'

class StepsApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createSteps = (payload: Steps) =>
    this.api.post<Response<any>>(
      `/gw1/puja/internal/v1/steps/create-steps`,
      payload,
    )

  getSteps = (
    step_type: string,
    entityId?: string,
    experimentCode?: string,
    variantCode?: string,
  ) => {
    const params: Record<string, string> = {
      type: step_type,
      ...(entityId && { entity_id: entityId }),
      ...(experimentCode && { experiment: experimentCode }),
      ...(variantCode && { variant: variantCode }),
    }
    console.log({ params })
    return this.api.get<Response<Step[]>>(
      `/gw1/puja/internal/v1/steps/steps-list`,
      {
        params,
      },
    )
  }

  updateSteps = (
    payload: Steps,
    experimentCode?: string,
    variantCode?: string,
  ) => {
    const params: Record<string, string> = {
      ...(experimentCode && { experiment: experimentCode }),
      ...(variantCode && { variant: variantCode }),
    }
    return this.api.put<Response<Step[]>>(
      `/gw1/puja/internal/v1/steps/update-steps`,
      payload,
      {
        params,
      },
    )
  }
  deleteStep = (id: string) =>
    this.api.delete<Response<Step[]>>(`/gw1/puja/internal/v1/steps/${id}`)
}

export default StepsApi
