import React, { useState, Suspense, useEffect, useCallback } from 'react'
import { Tabs, Spin } from 'antd'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import { CustomLoader } from '@/components/Loader'
import { useLocation, useSearchParams } from 'react-router-dom'

const AvatarSchedule = React.lazy(() => import('./components/schedule-list/avatar-schedules'))
const GodItemsSchedule = React.lazy(() => import('./components/schedule-list/god-items-schedules'))
const GodSchedule = React.lazy(() => import('./components/schedule-list/god-schedules'))

export const enum TAB {
    AVATAR = 'avatar',
    ITEM = 'item',
    GOD = 'god'
}

const Schedule: React.FC = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const type = queryParams.get('type') as string
    const [searchParams, setSearchParams] = useSearchParams()

    const [activeKey, setActiveKey] = useState<string>(type ? type : TAB.AVATAR)

    const renderTabContent = useCallback(() => {
        switch (activeKey) {
            case TAB.AVATAR:
                return <AvatarSchedule />
            case TAB.ITEM:
                return <GodItemsSchedule />
            case TAB.GOD:
                return <GodSchedule />
            default:
                return null
        }
    }, [activeKey])

    useEffect(() => {
        if (type) {
            setActiveKey(type)
        }
    }, [type])

    const onChange = (key: string) => {
        console.log({ key })
        setSearchParams({ type: key })
        setActiveKey(key)
    }

    return (
        <div style={{ width: '100%', padding: '0 40px', marginTop: '15px' }}>
            <div style={{ width: '100%' }}>
                <Tabs activeKey={activeKey} onChange={onChange}>
                    <Tabs.TabPane tab="Avatar schedule" key="avatar">
                        <Suspense fallback={<Spin indicator={<CustomLoader />} />}>
                            {activeKey === TAB.AVATAR && renderTabContent()}
                        </Suspense>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Item schedule" key="item">
                        <Suspense fallback={<Spin indicator={<CustomLoader />} />}>
                            {activeKey === TAB.ITEM && renderTabContent()}
                        </Suspense>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="God schedule" key="god">
                        <Suspense fallback={<Spin indicator={<CustomLoader />} />}>
                            {activeKey === TAB.GOD && renderTabContent()}
                        </Suspense>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default withDefaultLayout(Schedule)