/* eslint-disable no-constant-condition */
import { Button, Col, Form, Card, Space, InputNumber, Rate, Input, Row, Spin } from 'antd'
import styled from 'styled-components'

import React from 'react'

import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import S3Upload from '@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload'
import { CustomLoader } from '@/components/Loader'


const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px;
`
const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
interface TempleCreateOrUpdateProps {
    cdnUrl: string
    preSignUrl: string
    form: any
    previousHandler: () => void
    currentStep: number
    isEditJourney: boolean
    submitFormHandler: () => void
    deleteReview: (id: string, callback: any) => void
    loader: boolean
}

const ReviewCreation: React.FC<TempleCreateOrUpdateProps> = ({
    cdnUrl,
    preSignUrl,
    form,
    previousHandler,
    submitFormHandler,
    currentStep,
    isEditJourney,
    deleteReview,
    loader
}) => {
    return (
        <Container
            title='Create Reviews'
            style={{ width: '100%' }}
        >
            <Col span={32}>
                {
                    loader ?
                        <Row style={{ width: '100%' }} justify='center'>
                            <Spin indicator={<CustomLoader />} />
                        </Row>
                        :
                        <Form
                            form={form}
                            layout='vertical'
                            name='create reviews'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            initialValues={{ remember: true }}
                            onFinish={submitFormHandler}
                        >
                            <Form.Item
                                name='data'
                                rules={[
                                    { required: false },
                                ]}
                            >
                                <Form.List name={'data'}>
                                    {(fields, { add, remove }) => {
                                        return (
                                            <>
                                                {fields.map((field: any, index: number) => {
                                                    return (
                                                        <div key={index} style={{
                                                            border: '1.5px dashed #e0dcdc',
                                                            marginRight: '0px',
                                                            marginBottom: '10px',
                                                            padding: '10px',
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <Form.Item
                                                                label={`User Image url`}
                                                                name={[field.name, 'user_image_url']}
                                                                valuePropName='fileList'
                                                                style={{ marginBottom: '0px' }}
                                                                rules={[
                                                                    {
                                                                        required: false,
                                                                        min: 0,
                                                                        max: 1,
                                                                        validator: async (
                                                                            rules: any,
                                                                            values: any,
                                                                        ) => {
                                                                            if (values?.length < rules.min) {
                                                                                return Promise.reject(
                                                                                    'Please Upload Image',
                                                                                )
                                                                            }
                                                                        },
                                                                    },
                                                                ]}
                                                            >
                                                                <S3Upload
                                                                    cdnUrl={cdnUrl}
                                                                    preSignUrl={preSignUrl}
                                                                    min={1}
                                                                    max={1}
                                                                />
                                                            </Form.Item>
                                                            <Col span={5}>
                                                                <Form.Item
                                                                    label={`User Name`}
                                                                    name={[field.name, 'id']}
                                                                    labelCol={{ span: 24 }}
                                                                    wrapperCol={{ span: 24 }}
                                                                    hidden
                                                                >
                                                                    <Input placeholder="Id" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label={`User Name`}
                                                                    name={[field.name, 'user_name']}
                                                                    rules={[{ required: true, message: 'User Name' }]}
                                                                    labelCol={{ span: 24 }}
                                                                    wrapperCol={{ span: 24 }}
                                                                >
                                                                    <Input placeholder="Enter User Name" />
                                                                </Form.Item>
                                                                <Row justify="space-between">
                                                                    <Form.Item
                                                                        label={`Rating`}
                                                                        name={[field.name, 'rating']}
                                                                        labelCol={{ span: 24 }}
                                                                        wrapperCol={{ span: 24 }}
                                                                        rules={[
                                                                            { required: true, message: 'Please input Rating' },
                                                                        ]}
                                                                    >
                                                                        <Rate allowHalf />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label={`Position`}
                                                                        name={[field.name, 'position']}
                                                                        labelCol={{ span: 24 }}
                                                                        wrapperCol={{ span: 24 }}
                                                                        rules={[
                                                                            { required: true, message: 'Please Position' },
                                                                        ]}
                                                                    >
                                                                        <InputNumber placeholder='Enter Position' />
                                                                    </Form.Item>
                                                                </Row>
                                                            </Col>
                                                            <Form.Item
                                                                label={`Review`}
                                                                name={[field.name, 'review']}
                                                                rules={[{ required: true, message: 'Review' }]}
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                                style={{ width: '30%' }}
                                                            >
                                                                <TextArea rows={5} placeholder="Enter Review" />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label={`Agent Reply`}
                                                                name={[field.name, 'agent_reply']}
                                                                rules={[{ required: false }]}
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                                style={{ width: '30%' }}
                                                            >
                                                                <TextArea rows={5} placeholder="Enter Agent reply" />
                                                            </Form.Item>
                                                            <Form.Item
                                                                noStyle
                                                                shouldUpdate={(prevValues, currentValues) => {
                                                                    return true
                                                                }
                                                                }
                                                            >
                                                                {({ getFieldValue }: any) => {
                                                                    return <Button
                                                                        onClick={() => {
                                                                            const id = getFieldValue(['data', field.name, 'id']);
                                                                            if (!id) {
                                                                                remove(field.name)
                                                                            } else {
                                                                                deleteReview(id, () => {

                                                                                    remove(field.name)
                                                                                })
                                                                            }
                                                                        }}
                                                                        type='ghost'
                                                                        icon={<DeleteOutlined />}
                                                                        size='middle'
                                                                        danger
                                                                    />
                                                                }}
                                                            </Form.Item>
                                                        </div>
                                                    )
                                                })}
                                                <Col span={24} style={{ paddingTop: '5px' }}>
                                                    <Row justify='start'>
                                                        <Col span={4}>
                                                            <Button
                                                                onClick={() => {
                                                                    add()
                                                                }}
                                                                type='ghost'
                                                                icon={<PlusCircleOutlined />}
                                                                size='middle'
                                                            >
                                                                Add Reviews
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </>
                                        )
                                    }}
                                </Form.List>
                            </Form.Item>
                            <Col span={24}>
                                <LabelRightAlign>
                                    <Space>
                                        {currentStep > 0 && (
                                            <Button type='primary' onClick={previousHandler}>
                                                Previous
                                            </Button>
                                        )}
                                        {isEditJourney && currentStep !== 1 && (
                                            <Button type='primary'>
                                                Update
                                            </Button>
                                        )}
                                        {currentStep < 3 && currentStep !== 1 && !isEditJourney ? (
                                            <Button type='primary' htmlType="submit">
                                                Submit
                                            </Button>
                                        ) : (
                                            currentStep !== 2 && (
                                                <Button type='primary' htmlType="submit">
                                                    Submit
                                                </Button>
                                            )
                                        )}
                                    </Space>
                                </LabelRightAlign>
                            </Col>
                        </Form >
                }
            </Col >
        </Container >
    )
}
export default React.memo(ReviewCreation)
