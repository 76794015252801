import {
  AddonsIdsMapping,
  InventoryIdsMapping,
  ReschedulePayload,
} from '@a4b/api/src/modules/Monetisation/Puja/types/reschedule'

export const generateReschedulePayload = (data: any): ReschedulePayload => {
  const inventoryIdsMapping: InventoryIdsMapping = {}
  const addonsIdsMapping: AddonsIdsMapping = {}

  data?.pujaPackage?.oldInventories?.forEach(
    (oldInventory: any, index: number) => {
      const newInventory = data?.pujaPackage?.newInventories?.[index]
      if (oldInventory && newInventory) {
        inventoryIdsMapping[oldInventory.value] = newInventory
      }
    },
  )

  data?.addons?.oldInventories?.forEach((oldInventory: any, index: number) => {
    const newInventory = data?.addons?.newInventories?.[index]
    if (oldInventory && newInventory) {
      addonsIdsMapping[oldInventory.value] = newInventory
    }
  })

  return {
    new_store_id: data.newStoreId,
    inventory_ids_mapping: inventoryIdsMapping,
    addons_ids_mapping: addonsIdsMapping,
  }
}
