import React, { useEffect, useState, useCallback } from 'react'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import OrderList from './components/OrderList'
import OrderDetailsForm from './components/OrderEditForm'
import { CreateStore, Order, SendNotification } from '@a4b/api/src/modules/Monetisation/Puja/types'
import styled from 'styled-components'
import { useForm } from 'antd/lib/form/Form'
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from '@/utils/constants'
import {
    Card,
    Col,
    Input,
    Modal,
    Row,
    Select,
    Space,
    Tag,
    message,
    Typography,
    Radio,
    Drawer
} from 'antd'

import { parseOrders } from './utils/helper'
import { useSearchParams } from 'react-router-dom'
import OrderRescheduleDetailsForm from './components/OrderRescheduleForm'
import RescheduledDetails from '../components/views/RescheduledDetails'
const { Text } = Typography

const { Search } = Input
const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  .ant-card-body {
    flex-grow: 1;
  }
  .ant-card-head-wrapper {
    display: block;
    width: 100%;
  }
  .ant-card-head-title {
    flex-basis: 30%;
    padding-bottom: 0px;
  }
  .ant-card-extra {
    flex-basis: 70%;
  }
`
const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`
interface FiltersType {
    serviceType: string
    orderStatus: string
    bookingId: string
    contactNumber: string
    userId: string
    storeId: string | undefined
    bookingWhatsAppNumber: string
    bookingMemberData: string
}

const actionMapping: any = {
    offeringUserName: "UPDATE_OFFERING_USER_NAME",
    memberData: "UPDATE_BOOKING_MEMBER_DATA",
    sku: "UPDATE_INVENTORY_VIDEO",
    address: "UPDATE_PRASAD_DETAILS",
    refund: "REFUND",
    whatsappNumber: "UPDATE_WHATSAPP_NUMBER"
}

const PoojaOrders = () => {
    const { networkInstance, monetisationRoles, userProfile, app } = useAppContext()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
    const [orderDetailsForm] = useForm()
    const [orderData, setOrderData] = useState<Order[]>()
    const [messageApi, contextHolder] = message.useMessage()
    const [loading, setLoading] = useState<boolean | null>(null)
    const [showOrderDetailsForm, setShowOrderDetailsForm] = useState<boolean>(false)
    const [editOrder, setEditOrder] = useState<any>()
    const [currentOrder, setCurrentOrder] = useState<any>()
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [remark, setRemark] = useState<string>('')
    const [pageNumber, setPageNumber] = useState<number | null>(1)
    const [isLastPage, setIsLastPage] = useState<boolean>(false)

    const [storeList, setStoreList] = useState<CreateStore[]>([])
    const [searchParams] = useSearchParams()
    const [filters, setFilters] = useState<FiltersType>({
        serviceType: 'pooja',
        orderStatus: 'booked',
        bookingId: '',
        contactNumber: '',
        userId: '',
        bookingWhatsAppNumber: '',
        bookingMemberData: '',
        storeId: undefined
    })

    //order reschedule states
    const [showOrderRescheduleDetailsForm, setShowOrderRescheduleDetailsForm] = useState<boolean>(false)
    const [showOrderRescheduleDetails, setShowOrderRescheduleDetails] = useState<boolean>(false)


    const getStoreList = useCallback((async () => {
        try {
            const storeList = await monetisationPujaApi.storesApi.getStoreList(
                {
                    limit: 2000,
                    offset: 0,
                },
                searchParams.get("country_code") || "IN",
                undefined,
                searchParams.get("language_code") || "hi",
                undefined,
                undefined,
                undefined,
                filters?.serviceType
            )

            setStoreList(storeList?.data?.data?.store_list)
            if (storeList?.data?.data?.store_list?.length > 0) {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    storeId: storeList?.data?.data?.store_list?.[0]?.id
                }))
            }

        } catch (error) {

            message.error({
                content: 'Failed to fetch store list',
                duration: 3,
            })
            console.log(error)
        }
    }), [filters?.serviceType, monetisationPujaApi.storesApi, searchParams])


    useEffect(() => {
        getStoreList()
    }, [getStoreList])

    const getPoojaOrders = useCallback(async () => {
        setLoading(true)
        try {
            const orderResponse = await monetisationPujaApi.orderApi.getOrders(
                String(pageNumber),
                filters.orderStatus,
                filters.serviceType,
                filters.bookingId,
                filters.contactNumber,
                filters.userId,
                filters.storeId!,
                filters.bookingWhatsAppNumber,
                filters.bookingMemberData
            )
            const parsedOrderData = parseOrders(orderResponse?.data?.data, filters?.serviceType)
            setIsLastPage(orderResponse?.data?.data?.is_last_page)
            setOrderData(parsedOrderData)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setPageNumber(null)
            console.log("Error: ", error)
        }
    }, [filters?.orderStatus, filters.bookingId, filters.bookingWhatsAppNumber, filters.contactNumber, filters.serviceType, filters.storeId, filters.userId, filters.bookingMemberData, monetisationPujaApi.orderApi, pageNumber])

    useEffect(() => {
        if (filters?.storeId)
            getPoojaOrders()
    }, [filters?.storeId, getPoojaOrders])

    const sendNotification = async (record: any) => {
        const notificationPayload: SendNotification = {
            type: "trigger-event",
            event_properties: {
                order_id: record?.id,
                event_name: "puja_sankalp_form_not_filled_backend"
            }
        }
        try {
            await monetisationPujaApi.systemApi.triggerEvent(notificationPayload)
            messageApi.open({
                type: 'success',
                content: 'Notification has been sent',
            });
        } catch (error) {
            console.log("Error while sending notification ", error)
        }
    }


    const handleTableChange = (type: string) => {
        if (type === 'previous' && pageNumber! > 1) {
            setPageNumber(pageNumber! - 1)
        } else if (type === 'next' && !isLastPage) {
            setPageNumber(pageNumber! + 1)
        }
    }

    const filterOrders = (type: string, value: string) => {
        setPageNumber(1)
        setFilters((prevFilters: FiltersType) => ({
            ...prevFilters,
            [type]: value
        }))
    }


    const closeOrderDetailsForm = (isOrderUpdated?: boolean) => {
        setShowOrderDetailsForm(false)
        orderDetailsForm?.resetFields()
        if (isOrderUpdated)
            setTimeout(() => {
                getPoojaOrders()
            }, 500)
    }

    const closeOrderRescheduleDetailsForm = (isRescheduled?: boolean) => {
        setShowOrderRescheduleDetailsForm(false)
        if (isRescheduled) {
            setTimeout(() => {
                getPoojaOrders()
            }, 500)
        }
    }

    const triggerEdit = (value: any) => {
        const orderData = { ...value, service_type: filters?.serviceType }
        setShowOrderDetailsForm(true)
        setEditOrder(orderData);
    }

    const triggerReschedule = (value: any) => {
        const orderData = { ...value, service_type: filters?.serviceType }
        setShowOrderRescheduleDetailsForm(true)
        setEditOrder(orderData)
    }

    const triggerRescheduleView = (value: any) => {
        setShowOrderRescheduleDetails(true)
        setEditOrder(value)
    }

    const handleRefund = (value: any) => {
        setCurrentOrder(value)
        setShowConfirmationModal(true)
    }

    const onModalOk = async () => {
        if (remark?.length <= 0) {
            message?.warning('Remark is required!')
        } else {
            if (monetisationRoles?.user_type === 'super_admin') {
                try {
                    await monetisationPujaApi.orderApi.updateOrder(
                        currentOrder?.id,
                        actionMapping?.['refund'],
                        { user_email: userProfile?.email!, remarks: remark }
                    )
                    message.success('Order has been successfully marked as refunded!')
                    setTimeout(() => {
                        getPoojaOrders()
                    }, 500)
                    setRemark('')
                    onModalCancel()
                } catch (e) {
                    message.error('Error while updating refund status!')
                    onModalCancel()
                    console.log(e)
                }
            } else {
                message.warning('Need Super Admin Access to mark as refund!')
                onModalCancel()
            }
        }

    }

    const onModalCancel = () => {
        setRemark('')
        setShowConfirmationModal(false)
    }

    const handleUpdateOrders = useCallback(() => {
        setTimeout(() => {
            getPoojaOrders()
        }, 2000)
    }, [getPoojaOrders])

    return (
        <div style={{ padding: '0 20px' }}>
            <Container
                title='Manage Orders'
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }
                }
                extra={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Row style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <Search
                                placeholder='Booking Id'
                                style={{ width: '12%', marginRight: '10px' }}
                                onSearch={(value: string) => {
                                    filterOrders('bookingId', value)
                                }}
                                allowClear
                            />
                            <Search
                                placeholder='User Id'
                                style={{ width: '12%', marginRight: '10px' }}
                                onSearch={(value: string) => {
                                    filterOrders('userId', value)
                                }}
                                allowClear
                            />
                            <Select value={filters['storeId']} showSearch style={{ width: '26%', marginRight: '10px' }} onChange={(value: string) => {
                                filterOrders('storeId', value)
                            }}>
                                {
                                    storeList?.map((store: CreateStore) => {
                                        return <Select.Option key={store.id} value={store.id}>{store.title}</Select.Option>
                                    })
                                }
                            </Select>

                            <Select
                                size='middle'
                                placeholder="Order Status"
                                style={{ width: '9%', marginRight: '10px' }}
                                defaultValue={filters?.orderStatus}
                                options={[
                                    {
                                        label: 'Booked',
                                        value: 'booked'
                                    },
                                    {
                                        label: 'Refunded',
                                        value: 'refunded'
                                    },
                                    {
                                        label: 'Completed',
                                        value: 'completed'
                                    },
                                    {
                                        label: 'Live',
                                        value: 'live'
                                    },
                                ]}
                                onChange={(value: string) => {
                                    filterOrders('orderStatus', value)
                                }}
                            />
                            <Search
                                placeholder='Whats app Number'
                                style={{ width: '16%', marginRight: '10px' }}
                                onSearch={(value: string) => {
                                    filterOrders('bookingWhatsAppNumber', value)
                                }}
                                allowClear
                            />
                        </Row>
                        <div style={{ marginTop: '10px' }}>
                            <Row style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                {filters?.serviceType === 'pooja' && <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <span>
                                            MemberData filled:
                                        </span>
                                        <div style={{ marginLeft: '10px', display: 'flex' }}>
                                            <Radio.Group
                                                defaultValue={filters?.bookingMemberData}
                                                onChange={(e) => {
                                                    filterOrders('bookingMemberData', e.target.value)
                                                }}
                                                buttonStyle="solid"
                                                optionType="button"
                                            >
                                                <Radio.Button value="false">Yes</Radio.Button>
                                                <Radio.Button value="true">No</Radio.Button>
                                                <Radio.Button value="">Both</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                </div>}
                            </Row>
                        </div>
                    </div>
                }
            >
                <Space direction={'vertical'} style={{ width: '100%' }}></Space>
                <Row>
                    <Col span={28}>
                        {contextHolder}
                        <OrderList
                            orderData={orderData}
                            handleTableChange={handleTableChange}
                            sendNotification={sendNotification}
                            filters={filters}
                            triggerEdit={triggerEdit}
                            triggerReschedule={triggerReschedule}
                            triggerRescheduleView={triggerRescheduleView}
                            refundHandler={handleRefund}
                            userRole={monetisationRoles?.user_type!}
                            loading={loading || false}
                            currentPage={pageNumber}
                        />
                    </Col>
                </Row>
                <LabelRightAlign>
                </LabelRightAlign>
                {
                    showOrderDetailsForm &&
                    <OrderDetailsForm
                        showItemCreation={showOrderDetailsForm}
                        closeItemCreation={closeOrderDetailsForm}
                        form={orderDetailsForm}
                        initialValues={editOrder}
                        cdnUrl={YODA_CDN_URL}
                        preSignUrl={PUJA_PRESIGN_URL}
                        userRole={monetisationRoles?.user_type!}
                        handleUpdateOrders={handleUpdateOrders}
                    />
                }
                {
                    showOrderRescheduleDetailsForm &&
                    <OrderRescheduleDetailsForm
                        showOrderRescheduleForm={showOrderRescheduleDetailsForm}
                        closeOrderRescheduleForm={closeOrderRescheduleDetailsForm}
                        initialValues={editOrder}
                    />
                }
                {
                    showOrderRescheduleDetails &&
                    <Drawer
                        title={
                            <Row justify='space-between'>
                                <span>Order Reschedule</span>
                                <div>
                                    Order ID: <Tag color='blue'>{editOrder?.id}</Tag>
                                </div>
                            </Row>}
                        placement='right'
                        onClose={() => setShowOrderRescheduleDetails(false)}
                        visible={showOrderRescheduleDetails}
                        destroyOnClose={true}
                        size='large'
                        width={900}
                    >
                        <RescheduledDetails
                            formType={'order'}
                            id={editOrder?.id}
                        />
                    </Drawer >
                }
                <Modal
                    title='Refund Confirmation'
                    visible={showConfirmationModal}
                    onOk={onModalOk}
                    onCancel={onModalCancel}
                    okText=' Yes'
                >
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <span>Order Id: <Tag color='blue'>{currentOrder?.id}</Tag></span>
                        <input
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            placeholder='Enter remarks'
                            style={{ marginTop: '15px', width: '90%' }}
                        />
                        <Text strong style={{ marginTop: '20px', marginBottom: '0px' }}>Are you sure want to mark this order as Refunded?</Text>
                    </div>
                </Modal>
            </Container >
        </div>
    )
}

export default withDefaultLayout(PoojaOrders)
