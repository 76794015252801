import { Button, message } from "antd";
import { FormTypeValues } from "../../form/RescheduleForm";
import { useCallback, useEffect, useState } from "react";
import { A4B_APPS } from "@/utils/constants";
import { useAppContext } from "@/components/AppContext";
import { RescheduledDetailsData } from "@a4b/api/src/modules/Monetisation/Puja/types/reschedule";
import styled from "styled-components";
import { CopyOutlined } from "@ant-design/icons";

const StyledCard = styled.div`
    background: #FAFAFA;
    margin-top: 20px;
    padding: 20px;
`;

const StyledList = styled.ul`
    padding: 0;
`;

const StyledListItem = styled.li`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
`;

const Label = styled.span`
    min-width: 130px;
`;

const RescheduledDetailsUi = ({ title, storeId, bookingId, inventoryIds, addonIds, type }: any) => {
    const allIds = [...inventoryIds, ...addonIds]
    return (
        <StyledCard>
            <h3>{title}</h3>
            <StyledList>
                <StyledListItem>
                    <Label>{type} Store ID</Label> {`: ${storeId}`}
                    <Button onClick={() => {
                        navigator.clipboard.writeText(storeId)
                        message.info('Link copied', 2)
                    }
                    }
                        style={{ marginLeft: '20px' }} type="link" icon={<CopyOutlined />}>
                    </Button>
                </StyledListItem>
                <StyledListItem>
                    <Label>{type} Booking ID</Label> {`: ${bookingId}`}
                </StyledListItem>
                <div style={{ marginTop: '20px' }}>
                    {allIds.map(id => (
                        <StyledListItem key={id}>
                            <Label>{type} Inventory ID</Label> {`: ${id}`}
                        </StyledListItem>
                    ))}
                </div>
            </StyledList>
        </StyledCard>
    );
};

interface RescheduledDetailsProps {
    formType: FormTypeValues
    id: string
}

const RescheduledDetails: React.FC<RescheduledDetailsProps> = ({ formType, id }) => {
    const { networkInstance, app } = useAppContext()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
    const [loading, setLoading] = useState<boolean>(false)
    const [rescheduledDetails, setRescheduledDetails] = useState<RescheduledDetailsData>()

    const getPoojaRescheduledDetails = useCallback(
        async (storeId: string): Promise<RescheduledDetailsData | undefined> => {
            setLoading(true)
            try {
                const { data } = await monetisationPujaApi.rescheduleApi.pujaRescheduledDetails(storeId)
                return data?.data
            } catch (error) {
                console.error(error)
                message.error('Error while fetching store rescheduled details', 3)
                return undefined
            } finally {
                setLoading(false)
            }
        },
        [monetisationPujaApi.rescheduleApi]
    )

    const getOrderRescheduledDetails = useCallback(
        async (orderId: string) => {
            setLoading(true)
            try {
                const { data } = await monetisationPujaApi.rescheduleApi.orderRescheduledDetails(orderId)
                return data?.data
            } catch (error) {
                console.error(error)
                message.error('Error while fetching order rescheduled details', 3)
                return undefined
            } finally {
                setLoading(false)
            }
        },
        [monetisationPujaApi.rescheduleApi]
    )

    const fetchData = useCallback(async (id) => {
        let data: RescheduledDetailsData | undefined = undefined
        if (formType === 'order') {
            data = await getOrderRescheduledDetails(id)
        } else {
            data = await getPoojaRescheduledDetails(id)
        }
        if (!data) {
            message.warning('Data is empty')
            return
        }
        console.log('data----->', data)
        setRescheduledDetails(data)
    }, [formType, getOrderRescheduledDetails, getPoojaRescheduledDetails])

    useEffect(() => {
        if (!id) {
            message.error(formType + ' - ID not found')
            return
        }
        fetchData(id)
    }, [fetchData, formType, id])


    return (
        <div style={{ width: '100%' }}>
            {rescheduledDetails && (
                <>
                    <RescheduledDetailsUi
                        title="Old Store Details"
                        storeId={rescheduledDetails.old_details.store_id}
                        bookingId={rescheduledDetails.old_details.booking_id}
                        inventoryIds={rescheduledDetails.old_details.inventory_ids}
                        addonIds={rescheduledDetails.old_details.addon_ids}
                        type='Old'
                    />
                    <RescheduledDetailsUi
                        title="New Store Details"
                        storeId={rescheduledDetails.rescheduled_details.store_id}
                        bookingId={rescheduledDetails.rescheduled_details.booking_id}
                        inventoryIds={rescheduledDetails.rescheduled_details.inventory_ids}
                        addonIds={rescheduledDetails.rescheduled_details.addon_ids}
                        type='New'
                    />
                </>
            )}
        </div>
    )
}

export default RescheduledDetails;