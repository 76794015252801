import { useAppContext } from '@/components/AppContext'
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from '@/utils/constants'
import { message, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import FaqReviewForm from '../components/FaqReviewForm'

import { LanguageState, Product, StoreSessionDay } from '@a4b/api/src/modules/Monetisation/Offerings/types'

import {
  formatFaqsAndreviewsApi
} from '../utils/helper'
import moment from 'moment'
import usePujaStore from '../../../stores/puja-store'

interface Props {
  isEditJourney: boolean
  currentStep: number
  storeId: string
  nextHandler: (store_id?: string) => void
  previousHandler: () => void
  languageState: LanguageState
}
type FaqReviewFormData = {
  faqs: Faqs[]
  reviews: reviews[]
}
type Faqs = { question: string; answer: string }

type reviews = {
  userReview: string
  userName: string
  userRating: number
  userImageUrl: string
}
type AddedItems = { added: Product[] }
const FaqsAndReviewStep: React.FC<Props> = ({
  currentStep,
  isEditJourney,
  storeId,
  previousHandler,
  nextHandler,
  languageState
}) => {
  const { networkInstance, countryState, app } = useAppContext()
  const { experiment, variant } = usePujaStore()
  const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
  const [faqFormInstance] = useForm()
  let navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [showStoreEnableModal, setShowStoreEnableModal] = useState(false)
  const [storeSessionJourney, setStoreSessionJourney] = useState("create")
  const [storeSession, setStoreSession] = useState<StoreSessionDay[] | []>([])
  const [serviceType, setServiceType] = useState<string>("")

  const [faqAndreviewData, setFaqAndreviewData] = useState<FaqReviewFormData>(
    () => ({
      faqs: [{ question: '', answer: '' }],
      reviews: [
        {
          userName: '',
          userReview: '',
          userRating: 5,
          userImageUrl: '',
        },
      ],
      status: true,
    }),
  )

  const getfaqList = useCallback(async () => {
    try {
      const faqList = await monetisationPujaApi.storesApi.getFaqs(storeId)
      const reviewList = await monetisationPujaApi.reviewApi.getReviews(storeId)
      const storeResponse = await monetisationPujaApi.storesApi.getStoreById(
        storeId,
      )
      const storeTimeResponse = await monetisationPujaApi.storesApi.getStoreTime(
        storeId,
      )
      const poojaBenefits = await monetisationPujaApi.stepsApi.getSteps("pooja_benefits", storeId, experiment, variant)
      const poojaProcess = await monetisationPujaApi.stepsApi.getSteps("pooja_process", storeId)
      setStoreSession(storeTimeResponse?.data?.data?.sessions)
      setServiceType(storeResponse?.data?.data?.service_type)

      const faqAndreviewData: any = {
        faqs: faqList?.data?.data,
        reviews: reviewList?.data?.data.map((item: any) => ({
          ...item,
          user_image_url: [item.user_image_url],
        })),
        pooja_benefits: poojaBenefits?.data?.data.map((item: any) => ({
          ...item,
          pooja_benefit_icon: [item.icon]
        })),
        pooja_process: poojaProcess?.data?.data.map((item: any) => ({
          ...item,
          pooja_process_icon: [item.icon]
        })),
        status: storeResponse?.data?.data?.active,
      }

      if (storeTimeResponse?.data?.data?.sessions?.length) {
        const { opening_hour, closing_hour } = storeTimeResponse?.data?.data?.sessions?.[0]
        let sessionList = []
        sessionList.push(moment(opening_hour, "HH:mm"))
        sessionList.push(moment(closing_hour, "HH:mm"))
        faqAndreviewData["session"] = sessionList
        setStoreSessionJourney("edit")

      } else {
        setStoreSessionJourney("create")
      }
      setFaqAndreviewData(faqAndreviewData)
      faqFormInstance.setFieldsValue(faqAndreviewData)
    } catch (error) {
      message.error({
        content: 'Error while fetching product list',
        duration: 3,
      })
      console.log(error)
    }
  }, [faqFormInstance, monetisationPujaApi.storesApi, storeId, experiment, variant])

  useEffect(() => {
    if (isEditJourney && currentStep === 2) {
      searchParams.set("language_code", languageState.monetisationLanguage)
      setSearchParams(searchParams)
      getfaqList()
    }
  }, [currentStep, getfaqList, isEditJourney])

  const deleteFaq = async (id: string, callback: any) => {
    try {
      await monetisationPujaApi.faqsApi.deleteFaq(id)
      message.success({
        content: 'Faq deleted successfully',
        duration: 3,
      })
      callback()
    } catch (error) {
      message.error({
        content: 'Faq deletion failed',
        duration: 2,
      })
      console.log(error)
    }
  }
  const deleteSteps = async (id: string, callback: any) => {
    try {
      await monetisationPujaApi.stepsApi.deleteStep(id)
      message.success({
        content: 'Step deleted successfully',
        duration: 3,
      })
      callback()
    } catch (error) {
      message.error({
        content: 'Step deletion failed',
        duration: 2,
      })
      console.log(error)
    }
  }
  const deleteReview = async (id: string, callback: any) => {
    try {
      await monetisationPujaApi.reviewApi.deleteReview(id)
      message.success({
        content: 'Review deleted successfully',
        duration: 3,
      })
      callback()
    } catch (error) {
      message.error({
        content: 'Review deletion failed',
        duration: 2,
      })
    }
  }

  const handleFaqCreateOrUpdate = async (values: any) => {
    if (isEditJourney) {
      const formatedData = formatFaqsAndreviewsApi(values, storeId, storeSession)
      if (storeSessionJourney === "edit") {
        if (values?.session?.length) {
          try {
            await monetisationPujaApi.storesApi.updateStoreTime(storeId, formatedData.session)
            message.success({
              content: 'Store Timing Updated Successfully',
              duration: 2,
              key: 'session',
            })
          } catch (error) {
            console.log('error occured while updating store timing', error)
            message.destroy('session')
          }
        }
      }
      if (storeSessionJourney === "create") {
        if (values?.session?.length) {
          try {
            await monetisationPujaApi.storesApi.createStoreTime(storeId, formatedData.session)
            message.success({
              content: 'Store Timing Created Successfully',
              duration: 2,
              key: 'session',
            })
          } catch (error) {
            console.log('error occured while updating store timing', error)
            message.destroy('session')
          }
        }
      }

      if (formatedData?.faqs?.faqs?.create?.faqs?.length) {
        try {
          await monetisationPujaApi.faqsApi.updateFaqs({
            ...formatedData?.faqs?.faqs?.create,
            language_code: languageState?.monetisationLanguage,
          })
          message.success({
            content: 'Faqs Created Successfully',
            duration: 2,
            key: 'faqs_create',
          })
        } catch (error) {
          console.log('error occured while creating faq', error)
          message.error({
            content: 'Error occured while creating faqs',
            duration: 2,
            key: 'faqs_create',
          })
        }
      }

      if (formatedData?.faqs?.faqs?.update?.faqs?.length) {
        try {
          await monetisationPujaApi.faqsApi.updateFaqs({
            ...formatedData?.faqs?.faqs?.update,
            language_code: languageState?.monetisationLanguage,
          })
          message.success({
            content: 'Faqs Updated Successfully',
            duration: 2,
            key: 'faqs_update',
          })
        } catch (error) {
          console.log('error occured while updating faq', error)
          message.error({
            content: 'Error occured while updating faqs',
            duration: 2,
            key: 'faqs_update',
          })
        }
      }

      if (formatedData?.reviews?.reviews?.create?.reviews?.length) {
        try {
          await monetisationPujaApi.reviewApi.updateReviews({
            ...formatedData?.reviews?.reviews?.create,
            language_code: languageState?.monetisationLanguage,
          }

          )
          message.success({
            content: 'Reviews Created Successfully',
            duration: 2,
            key: 'review_create',
          })
        } catch (error) {
          console.log('error occured while creating faq', error)
          message.error({
            content: 'Error occured while creating faqs and reviews',
            duration: 2,
            key: 'review_create',
          })
        }
      }
      if (formatedData?.reviews?.reviews?.update?.reviews?.length) {
        try {
          await monetisationPujaApi.reviewApi.updateReviews({
            ...formatedData?.reviews?.reviews?.update,
            language_code: languageState?.monetisationLanguage,
          })
          message.success({
            content: 'Reviews Updated Successfully',
            duration: 2,
            key: 'review_update',
          })
        } catch (error) {
          console.log('error occured while updating faq', error)
          message.destroy('review_update')
        }
      }

      if (formatedData?.pooja_benefits?.pooja_benefits?.create?.steps?.length) {
        try {
          await monetisationPujaApi.stepsApi.updateSteps({
            ...formatedData?.pooja_benefits?.pooja_benefits?.create,
            language_code: languageState?.monetisationLanguage,
          },
            experiment,
            variant
          )
          message.success({
            content: 'pooja_benefits Created Successfully',
            duration: 2,
            key: 'pooja_benefits',
          })
        } catch (error) {
          console.log('error occured while creating pooja_benefits', error)
          message.error({
            content: 'Error occured while creating pooja_benefits',
            duration: 2,
            key: 'pooja_benefits',
          })
        }
      }
      if (formatedData?.pooja_benefits?.pooja_benefits?.update?.steps?.length) {
        try {
          await monetisationPujaApi.stepsApi.updateSteps({
            ...formatedData?.pooja_benefits?.pooja_benefits?.update,
            language_code: languageState?.monetisationLanguage,
          }, experiment,
            variant
          )
          message.success({
            content: 'pooja_benefits Updated Successfully',
            duration: 2,
            key: 'pooja_benefits',
          })
        } catch (error) {
          console.log('error occured while updating pooja_benefits', error)
          message.error({
            content: 'Error occured while updating pooja_benefits',
            duration: 2,
            key: 'pooja_benefits',
          })
        }
      }

      if (formatedData?.pooja_process?.pooja_process?.create?.steps?.length) {
        try {
          await monetisationPujaApi.stepsApi.updateSteps({
            ...formatedData?.pooja_process?.pooja_process?.create,
            language_code: languageState?.monetisationLanguage,
          })
          message.success({
            content: 'pooja_process Created Successfully',
            duration: 2,
            key: 'pooja_process',
          })
        } catch (error) {
          console.log('error occured while creating pooja_process', error)
          message.error({
            content: 'Error occured while creating pooja_process',
            duration: 2,
            key: 'pooja_process',
          })
        }
      }
      if (formatedData?.pooja_process?.pooja_process?.update?.steps?.length) {
        try {
          await monetisationPujaApi.stepsApi.updateSteps({
            ...formatedData?.pooja_process?.pooja_process?.update,
            language_code: languageState?.monetisationLanguage,
          }
          )
          message.success({
            content: 'pooja_process Updated Successfully',
            duration: 2,
            key: 'pooja_process',
          })
        } catch (error) {
          console.log('error occured while updating pooja_process', error)
          message.error({
            content: 'Error occured while updating pooja_process',
            duration: 2,
            key: 'pooja_process',
          })
        }
      }
      if (formatedData.status) {
        try {
          setShowStoreEnableModal(true)
        } catch (error) {
          console.log('error occured while creating faq', error)
          message.success({
            content: 'Error occured while creating faqs and reviews',
            duration: 2,
            key: 'store_create',
          })
        }
      } else {
        setTimeout(() => {
          app === A4B_APPS.SRIMANDIR ?
            navigate('/monetisation/puja/temples') : navigate('/astro/puja/temples')
        }, 1000)
      }

    }
    else {
      const formatedData = formatFaqsAndreviewsApi(values, storeId, storeSession)
      if (storeSessionJourney === "edit") {
        if (values?.session?.length) {
          try {
            await monetisationPujaApi.storesApi.updateStoreTime(storeId, formatedData.session)
            message.success({
              content: 'Store Timing updated Successfully',
              duration: 2,
              key: 'session',
            })
          } catch (error) {
            console.log('error occured while updating store timing', error)
            message.destroy('session')
          }
        }
      }
      if (storeSessionJourney === "create") {
        if (values?.session?.length) {
          try {
            await monetisationPujaApi.storesApi.createStoreTime(storeId, formatedData.session)
            message.success({
              content: 'Store Timing Created Successfully',
              duration: 2,
              key: 'session',
            })
          } catch (error) {
            console.log('error occured while updating store timing', error)
            message.destroy('session')
          }
        }
      }

      if (formatedData.status) {
        try {
          setShowStoreEnableModal(true)
        } catch (error) {
          console.log('error occured while creating faq', error)
          message.success({
            content: 'Error occured while creating faqs and reviews',
            duration: 2,
            key: 'store_create',
          })
        }
      } else {
        setTimeout(() => {
          app === A4B_APPS.SRIMANDIR ?
            navigate('/monetisation/puja/temples') : navigate('/astro/puja/temples')
        }, 1000)
      }
    }
  }

  const handleTutorialsAndReview = () => {
    faqFormInstance
      .validateFields()
      .then((values: any) => {
        handleFaqCreateOrUpdate(values)
      })
      .catch((error: any) => { })
  }
  const onStoreEnableModalOk = async () => {
    setShowStoreEnableModal(false)

    message.loading({
      content: 'Enabling Store...',
      duration: 2,
      key: 'status_enable',
    })
    const payload = {
      active: faqFormInstance?.getFieldValue('status'),
      language_code: languageState.monetisationLanguage,
      country_code: countryState?.countryCode,
    }
    try {
      await monetisationPujaApi.storesApi.updateStoreStatus(
        storeId,
        payload
      )
      message.success({ content: 'Store Enabled Successfully', duration: 3 })
      setTimeout(() => {
        app === A4B_APPS.SRIMANDIR ?
          navigate('/monetisation/puja/temples') : navigate('/astro/puja/temples')
      }, 1000)
    } catch (error) {
      console.log('error occured while updating store status', error)
      message.destroy('status_enable')
    }
  }

  const onStoreEnableModalCancel = () => {
    setShowStoreEnableModal(false)
  }

  return (
    <>
      <FaqReviewForm
        form={faqFormInstance}
        cdnUrl={YODA_CDN_URL}
        preSignUrl={PUJA_PRESIGN_URL}
        faqFromInitialValues={faqAndreviewData}
        submitFormHandler={handleTutorialsAndReview}
        isEditJourney={isEditJourney}
        currentStep={currentStep}
        previousHandler={previousHandler}
        nextHandler={nextHandler}
        serviceType={serviceType}
        deleteFaq={deleteFaq}
        deleteSteps={deleteSteps}
        deleteReview={deleteReview}
      />
      <Modal
        title='Activate Store'
        visible={showStoreEnableModal}
        onOk={onStoreEnableModalOk}
        onCancel={onStoreEnableModalCancel}
        okText='Activate'
      >
        <p>Are you sure to activate this store ?</p>
      </Modal>
    </>
  )
}

export default FaqsAndReviewStep
