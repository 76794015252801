import React from 'react';
import { Table, Row, Col, Button, Switch, Tag, Tooltip } from 'antd';
import { CreateStore } from '@a4b/api/src/modules/Monetisation/Puja/types';
import { Link } from 'react-router-dom';

interface TableComponentProps {
    dataSource: CreateStore[];
    onStatusChange: (item: CreateStore) => void;
    archiveStore: (storeId: string) => void;
    unarchiveStore: (storeId: string) => void;
    cloneStore: (storeId: string, hubId: string) => Promise<void>;
    activeCloneId: string | undefined;
    loading: boolean;
    isSrimandir: boolean;
    getMasterLinkButton: (record: CreateStore) => JSX.Element;
    filterSearchTitleInput: () => JSX.Element;
    filterSearchIdInput: () => JSX.Element;
    setModelConfig: React.Dispatch<
        React.SetStateAction<{
            type: string;
            id: string;
            hubId?: string;
            callback: (id: string, hubId: string) => void;
        } | undefined>
    >;
}

const TableComponent: React.FC<TableComponentProps> = ({
    dataSource,
    onStatusChange,
    archiveStore,
    unarchiveStore,
    cloneStore,
    activeCloneId,
    loading,
    isSrimandir,
    getMasterLinkButton,
    filterSearchTitleInput,
    filterSearchIdInput,
    setModelConfig,
}) => {
    const columns = [
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Title</span>
                    {filterSearchTitleInput()}
                </div>
            ),
            dataIndex: 'title',
            key: 'title',
            width: '29%',
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Store Id</span>
                    {filterSearchIdInput()}
                </div>
            ),
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            ellipsis: true,
            render: (id: string) => (
                <Tooltip placement="topLeft" title={id}>
                    {id}
                </Tooltip>
            ),
        },
        {
            title: 'No of Items',
            dataIndex: 'no_of_items',
            key: 'no_of_items',
            width: '12%',
            render: (text: string) => <span>{text}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '12%',
            render: (_: string, record: any) => (
                <span>
                    {record.active ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}
                </span>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            width: '30%',
            render: (_: any, record: any) => (
                <Row style={{ alignItems: 'center' }}>
                    <Col span={5}>
                        <Switch checked={record.active} onChange={() => onStatusChange(record)} />
                    </Col>
                    <Col span={5}>
                        <Link
                            to={isSrimandir ? `/monetisation/puja/temple/create?edit=true&store_id=${record.id}` :
                                `/astro/puja/temple/create?edit=true&store_id=${record.id}`
                            }
                        >
                            <Button type='link'>Edit</Button>
                        </Link>
                    </Col>
                    <Col span={6}>
                        {record.archived ? (
                            <Button
                                type="link"
                                onClick={() =>
                                    setModelConfig({
                                        type: 'Unarchive',
                                        id: record.id,
                                        hubId: undefined,
                                        callback: unarchiveStore,
                                    })
                                }
                            >
                                Unarchive
                            </Button>
                        ) : (
                            <Button
                                type="link"
                                onClick={() =>
                                    setModelConfig({
                                        type: 'Archive',
                                        id: record.id,
                                        hubId: undefined,
                                        callback: archiveStore,
                                    })
                                }
                            >
                                Archive
                            </Button>
                        )}
                    </Col>
                    <Col span={6}>
                        <Button
                            size="small"
                            loading={activeCloneId === record.id}
                            onClick={() =>
                                setModelConfig({
                                    type: 'Clone',
                                    id: record.id,
                                    hubId: '',
                                    callback: cloneStore,
                                })
                            }
                        >
                            Clone
                        </Button>
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <Table
            sticky
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            loading={loading}
        />
    );
};

export default React.memo(TableComponent);