import { useCallback, useEffect, useState } from "react"
import { useAppContext } from "@/components/AppContext"
import { Button, Col, Divider, Drawer, Form, Input, InputNumber, Row, Select, Spin, Switch, Typography, message } from "antd"
import { useForm } from 'antd/lib/form/Form'
import { YODA_CDN_URL, PUJA_PRESIGN_URL, A4B_APPS } from '@/utils/constants'
import S3Upload from "@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload"
import { Category, CreateCategoryPayload } from '@a4b/api/src/modules/Monetisation/Puja/types/categories'
import { useSearchParams } from "react-router-dom"
import { prepareCategoryPayload, VIEW_TYPES } from "../../utils/helper"
import JourneyDetails from "./JourneyDetails"
import { CustomLoader } from '@/components/Loader'

const { Option } = Select

interface CategoryFormProps {
    showForm: boolean
    currentCategory: Category
    handleCloseForm: (updateStoreList?: boolean) => void
}

const CategoryForm: React.FC<CategoryFormProps> = ({ showForm, currentCategory, handleCloseForm }) => {
    const [categoryForm] = useForm()
    const { networkInstance, app } = useAppContext()
    const [searchParams] = useSearchParams()
    const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
    const [isEnabled, setIsEnabled] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState(false)
    const [viewType, setViewType] = useState<string>(currentCategory?.view_type)
    const [loading, setLoading] = useState<boolean>(true)

    const fetchCategoryInfo = useCallback(async () => {
        if (!currentCategory?.id) {
            setLoading(false)
            message.error('Category ID not found')
            return
        }
        try {
            const category = await monetisationPujaApi.categoriesApi.getCategoryById(currentCategory?.id)
            categoryForm.setFieldsValue(category?.data?.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            categoryForm.setFieldsValue(undefined)
            message.error({ content: 'Error while fetching category info', duration: 3 })
            console.log({ error })
        }
    }, [categoryForm, currentCategory?.id, monetisationPujaApi.categoriesApi])

    useEffect(() => {
        if (isEdit) {
            fetchCategoryInfo()
        } else {
            setLoading(false)
        }
    }, [fetchCategoryInfo, isEdit])

    useEffect(() => {
        setIsEdit(searchParams?.get('edit') === 'true')
    }, [searchParams])

    const handleValuesChange = (changedValues: any, allValues: any) => {
        console.log(changedValues)
        const { is_app, is_web } = allValues;

        if (changedValues?.view_type) {
            setViewType(changedValues?.view_type)
        }

        if (is_app && is_web) {
            message.warning('Both "Is App" and "Is Web" cannot be true at the same time.');
            categoryForm.setFieldsValue({ ...changedValues, ...{ [Object.keys(changedValues)[0]]: false } });
        }
    };

    const handleCategorySubmission = async (payload: CreateCategoryPayload) => {
        if (isEdit) {
            try {
                await monetisationPujaApi.categoriesApi.updateCategory(currentCategory?.id, payload)
                message.success({ content: 'Category updated Successfully', duration: 3 })
            } catch (error) {
                message.error({ content: 'Error while updating Category', duration: 3 })
                console.log({ error })
            }
            handleCloseForm(true)
        } else {
            try {
                await monetisationPujaApi.categoriesApi.createCategory(payload)
                message.success({ content: 'Category created Successfully', duration: 3 })
            } catch (error) {
                message.error({ content: 'Error while creating Category', duration: 3 })
                console.log({ error })
            }
        }
    }

    const submitForm = () => {
        categoryForm
            .validateFields()
            .then((values: any) => {
                const payload = prepareCategoryPayload(values)
                console.log({ payload })
                handleCategorySubmission(payload)
            })
            .catch((error) => {
                console.log('Category form validation error', error)
                message.error('Category form validation error', error)
            })
    }

    return (
        <Drawer
            title={
                <Col style={{ width: '100%' }}>
                    <span>{isEdit ? 'Update Category' : 'Create Category'}</span>
                </Col>
            }
            placement='left'
            onClose={() => {
                handleCloseForm()
                categoryForm?.resetFields()
            }}
            visible={showForm}
            destroyOnClose={true}
            width={viewType === VIEW_TYPES.JOURNEY ? 1000 : 800}
        >
            {
                loading ?
                    <Row justify="center" style={{ width: '100%' }}>
                        <Spin indicator={<CustomLoader />} />
                    </Row>
                    :
                    <Form
                        form={categoryForm}
                        layout='vertical'
                        initialValues={{ remember: true }}
                        onFinish={submitForm}
                        onFieldsChange={() => setIsEnabled(true)}
                        onValuesChange={handleValuesChange}
                        style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
                    >
                        <Col style={{ width: '100%' }}>
                            <div>
                                <Row justify='space-between' style={{ display: 'flex' }}>
                                    <div style={{ flexBasis: '48%' }}>
                                        <Form.Item
                                            label='Journey Name(Hindi)'
                                            name='name'
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                { required: true, message: 'Please input name of the Category!' },
                                            ]}
                                        >
                                            <Input placeholder='Enter Hindi Category name' />
                                        </Form.Item>
                                    </div>
                                    <div style={{ flexBasis: '48%' }}>
                                        <Form.Item
                                            label='Journey Name(English)'
                                            name='name_en'
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                { required: true, message: 'Please input name of the Category!' },
                                            ]}
                                        >
                                            <Input placeholder='Enter English Category name' />
                                        </Form.Item>
                                    </div>
                                </Row>

                                <Row justify='space-between' style={{ display: 'flex' }}>
                                    <div style={{ flexBasis: '48%' }}>
                                        <Form.Item
                                            label='Slug'
                                            name='slug'
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                { required: true, message: 'Please input name of the Slug!' },
                                            ]}
                                        >
                                            <Input placeholder='Enter slug' />
                                        </Form.Item>
                                    </div>
                                    <div style={{ flexBasis: '48%' }}>
                                        <Form.Item
                                            label='View type'
                                            name='view_type'
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select view type!',
                                                },
                                            ]}
                                        >
                                            <Select placeholder='Type'>
                                                <Option value='LIST'>LIST</Option>
                                                <Option value='GRID'>GRID</Option>
                                                <Option value='JOURNEY'>JOURNEY</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Row>
                                <Row justify='space-between' style={{ display: 'flex' }}>
                                    <Row justify='space-between' style={{ flexBasis: '48%', width: '100%', display: 'flex' }} >
                                        <div style={{ flexBasis: '48%' }}>
                                            <Form.Item
                                                label="Image"
                                                name='image'
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                dependencies={['view_type']}
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            const viewType = getFieldValue('view_type');
                                                            if (viewType === 'GRID' && (!value || value.length === 0)) {
                                                                return Promise.reject(new Error('Please upload Image'));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                                valuePropName='fileList'
                                            >
                                                <S3Upload
                                                    cdnUrl={YODA_CDN_URL}
                                                    preSignUrl={PUJA_PRESIGN_URL}
                                                    min={1}
                                                    max={1}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div style={{ flexBasis: '48%' }}>
                                            <Form.Item
                                                label="Background Image"
                                                name='bg_img_url'
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                valuePropName='fileList'
                                            >
                                                <S3Upload
                                                    cdnUrl={YODA_CDN_URL}
                                                    preSignUrl={PUJA_PRESIGN_URL}
                                                    min={0}
                                                    max={1}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Row>
                                    <div style={{ flexBasis: '48%' }}>
                                        <Row justify="space-between">
                                            <Form.Item
                                                label="Is Web"
                                                name='is_web'
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                valuePropName="checked"
                                            >
                                                <Switch />
                                            </Form.Item>
                                            <Form.Item
                                                label="Is App"
                                                name='is_app'
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                valuePropName="checked"
                                            >
                                                <Switch />
                                            </Form.Item>
                                            <Form.Item
                                                label={"Order"}
                                                name={"order"}
                                                labelCol={{ span: 12 }}
                                                wrapperCol={{ span: 12 }}
                                            >
                                                <InputNumber style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Row>
                                    </div>
                                </Row>
                            </div>

                            <Divider />

                            {
                                viewType === VIEW_TYPES.JOURNEY &&
                                <Form.Item
                                    label={<Typography.Title level={4}>Journey Details</Typography.Title>}>
                                    <JourneyDetails formInstance={categoryForm} />
                                </Form.Item>
                            }


                            <Button style={{ marginTop: '20px' }} htmlType="submit" disabled={!isEnabled}>{isEdit ? 'Update' : 'Create'}</Button>
                        </Col>
                    </Form>
            }
        </Drawer>
    )
}

export default CategoryForm