import React from 'react'
import { Modal, Tag } from 'antd'
import { SUPPORTED_COUNTRY_LIST, SUPPORTED_LANGUAGES_LIST, Country, Language } from '@a4b/ui/src/modules/admin/constants';
import usePujaStore from '@/modules/monetisation/puja/stores/puja-store';

interface Props {
    storeData: any
    visible: boolean
    handleOk: () => void
    handleCancel: () => void
    title: string
}

const ConfirmationPopup: React.FC<Props> = ({ visible, storeData, handleOk, handleCancel, title }) => {
    const { experiment, variant } = usePujaStore()
    return (
        <Modal
            title="Are you sure want to continue?"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText='Submit'
            cancelText='Edit'
            zIndex={2000}
            className='inventory-modal'
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold', width: 'fit-content' }}>{`You are updating the ${title} for below configuration.`}</span>
                <span style={{ marginTop: '15px' }}>
                    {title} title: {' '}
                    <Tag color='blue' style={{ wordWrap: 'break-word' }}>
                        {storeData?.title}
                    </Tag>
                </span>
                <>
                    <span style={{ marginTop: '15px' }}>
                        Language code: {' '}
                        <Tag color='blue'>
                            {SUPPORTED_LANGUAGES_LIST?.filter((language: Language) => language?.value === storeData?.language_code)?.[0]?.alt_name}
                        </Tag>
                    </span>
                    <span style={{ marginTop: '15px' }}>
                        Country code: {' '}
                        <Tag color='blue'>
                            {SUPPORTED_COUNTRY_LIST?.filter((country: Country) => country?.country_code === storeData?.country_code)?.[0]?.country_name}
                        </Tag>
                    </span>

                    {
                        experiment &&
                        <span style={{ marginTop: '15px' }}>
                            Experiment: {' '}
                            <Tag color='blue'>
                                {experiment}
                            </Tag>
                        </span>
                    }
                    {
                        variant &&
                        <span style={{ marginTop: '15px' }}>
                            Variant: {' '}
                            <Tag color='blue'>
                                {variant}
                            </Tag>
                        </span>
                    }
                </>
            </div>
        </Modal>
    )
}

export default ConfirmationPopup
