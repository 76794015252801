import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Button, Space, Select, Input, Card, Modal, Tag } from 'antd';
import styled from 'styled-components';
import TableComponent from './TableComponent'; // Adjust the import path
import { Link } from 'react-router-dom'
import { CreateStore, HubListItem } from '@a4b/api/src/modules/Monetisation/Puja/types';
import ManageMasterLinks from '@/modules/monetisation/puja/MasterLinks/ManageMasterLinks';
import { EditOutlined, LinkOutlined, PlusSquareOutlined } from '@ant-design/icons';
import CreateMasterLinks from '@/modules/monetisation/puja/MasterLinks/CreateMasterLinks';
import { MasterLink, MasterLinkList } from '@/modules/monetisation/puja/MasterLinks/types';
import { useAppContext } from '@/components/AppContext';
import { A4B_APPS } from '@/utils/constants';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  .ant-card-body {
    flex-grow: 1;
  }
`;
enum MODAL_TYPE {
    CLONE = 'Clone',
    ARCHIVE = "Archive",
    UNARCHIVE = "Unarchive"
}
const StoreListTable: React.FC<{
    templeList: CreateStore[];
    onStatusChange: (item: CreateStore) => void;
    archiveStore: (storeId: string) => void;
    unarchiveStore: (storeId: string) => void;
    cloneStore: (storeId: string, hubId: string) => Promise<void>;
    activeCloneId: string | undefined;
    sortBy: 'created_at' | 'position';
    isArchive?: 'true' | 'false';
    isActive?: 'true' | 'false';
    updateFilters: (
        updateType: 'sortBy' | 'isArchive' | 'isActive' | 'serviceType',
        value?: 'true' | 'false' | 'created_at' | 'position' | 'pooja'
    ) => void;
    loading: boolean;
    hubList: HubListItem[];
}> = ({
    templeList,
    onStatusChange,
    archiveStore,
    unarchiveStore,
    cloneStore,
    activeCloneId,
    sortBy,
    isArchive,
    isActive,
    updateFilters,
    loading,
    hubList,
}) => {
        const { networkInstance, app } = useAppContext()
        const monetisationPujaApi = app === A4B_APPS.SRIMANDIR ? networkInstance.clientWithHeaders.monetisationPujaApi : networkInstance.clientWithHeaders.monetisationAstroPujaApi
        const isSrimandir = app === A4B_APPS.SRIMANDIR
        const [dataSource, setDataSource] = useState(templeList);
        const [title, setTitle] = useState('');
        const [id, setId] = useState('');
        const [showMasterLinkDrawer, setMasterLinkDrawer] = useState(false);
        const [modelConfig, setModelConfig] = useState<{
            type: string;
            id: string;
            hubId?: string;
            callback: (id: string, hubId: string) => void;
        }>();
        const [showLinkCreateModal, setShowLinkCreateModal] = useState<{ show: boolean, masterLink: MasterLink | null }>({ show: false, masterLink: null })
        const [masterLinks, setMasterLinks] = useState<MasterLinkList>()

        useEffect(() => {
            setDataSource(templeList);
        }, [templeList]);




        const getMasterLinks = async () => {
            if (!templeList) { return }
            try {
                const masterLinkList = await monetisationPujaApi?.storesApi?.getMasterLinks()
                const masterLinks = masterLinkList?.data?.data?.master_slugs?.map((masterLink: MasterLink) => {
                    const storeData = templeList?.find((store: CreateStore) => store.id === masterLink?.store_id!)
                    return { ...masterLink, title: storeData?.title }
                })
                setMasterLinks({ master_slugs: masterLinks })
            } catch (error) {
                console.error(error)
            }
        }
        useEffect(() => {
            getMasterLinks()
        }, [templeList])


        const filterSearchTitleInput = () => (
            <Input
                placeholder="Search Title"
                value={title}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setTitle(currValue);
                    const filteredData = templeList.filter((entry) =>
                        entry.title.toLowerCase().includes(currValue.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
                allowClear
            />
        );

        const filterSearchIdInput = () => (
            <Input
                placeholder="Search ID"
                value={id}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setId(currValue);
                    const filteredData = templeList.filter((entry) =>
                        entry.id.toLowerCase().includes(currValue.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
                allowClear
            />
        );

        const callbackFromCreateMasterLink = (linkData: { show: boolean, masterLink: MasterLink | null }) => {
            setShowLinkCreateModal({ show: linkData?.show, masterLink: linkData?.masterLink })
            getMasterLinks()
        }
        const triggerMasterLinkModal = useCallback((record: any) => {
            const currentMasterLink = masterLinks?.master_slugs?.find((masterLink: MasterLink) => record.id === masterLink?.store_id) || null
            setShowLinkCreateModal({ show: true, masterLink: { ...currentMasterLink, store_id: record?.id } })
        }, [masterLinks])
        const toggleMasterLinkModal = useCallback((record: any) => {
            const currentMasterLink = masterLinks?.master_slugs?.find((masterLink: MasterLink) => record.id === masterLink?.store_id) || null
            setShowLinkCreateModal({ show: true, masterLink: { ...currentMasterLink, store_id: record?.id } })
        }, [masterLinks])

        const showCreateMasterLinkModal = useCallback(() => {
            setShowLinkCreateModal({ show: true, masterLink: null })
        }, [masterLinks])

        const getMasterLinkButton = useCallback((record: any) => {
            const currentMasterLink = masterLinks?.master_slugs?.find((masterLink: MasterLink) => record?.id === masterLink?.store_id)
            return <div style={{ marginLeft: "2px", marginRight: "2px" }}>
                <Tag style={{ cursor: "pointer" }} color={currentMasterLink?.id && currentMasterLink?.active ? "success" : currentMasterLink?.id && !currentMasterLink?.active ? "error" : "geekblue"} onClick={() => toggleMasterLinkModal(record)}  >
                    {currentMasterLink?.id ? <EditOutlined /> : < PlusSquareOutlined />}
                    <LinkOutlined /> Links
                </Tag>


            </div >
        }, [masterLinks])

        return (
            <Container title="Manage Stores" style={{ width: '100%' }} extra={<Row style={{ width: '100%', justifyContent: 'space-between', marginBottom: 16 }}>
                <Col>
                    <label>Filter by:</label>
                    <Select
                        placeholder="Is archived"
                        value={isArchive}
                        onChange={(value) => updateFilters('isArchive', value)}
                        style={{ width: 120, marginLeft: 8 }}
                    >
                        <Select.Option value="true">Archived</Select.Option>
                        <Select.Option value="false">Unarchived</Select.Option>
                    </Select>
                    <Select
                        placeholder="Status"
                        value={isActive}
                        onChange={(value) => updateFilters('isActive', value)}
                        style={{ width: 120, marginLeft: 8 }}
                    >
                        <Select.Option value="true">Active</Select.Option>
                        <Select.Option value="false">Inactive</Select.Option>
                    </Select>
                </Col>
                <Col>
                    <label>Sort by:</label>
                    <Select
                        value={sortBy}
                        onChange={(value) => updateFilters('sortBy', value)}
                        style={{ width: 120, marginLeft: 8 }}
                    >
                        <Select.Option value="created_at">Created at</Select.Option>
                        <Select.Option value="position">Position</Select.Option>
                    </Select>
                </Col>
                <Col style={{ paddingLeft: '10px' }}>
                    <Button type='primary'>
                        <Link to={isSrimandir ? '/monetisation/puja/temple/create' : '/astro/puja/temple/create'}>
                            <PlusSquareOutlined /> Add new Store
                        </Link>
                    </Button>
                </Col>
                <Col style={{ paddingLeft: '10px' }}>
                    <Button type='primary' onClick={() => setMasterLinkDrawer(true)}>
                        <PlusSquareOutlined /> View Master Links
                    </Button>
                </Col>
            </Row>}>

                <TableComponent
                    dataSource={dataSource}
                    onStatusChange={onStatusChange}
                    archiveStore={archiveStore}
                    unarchiveStore={unarchiveStore}
                    cloneStore={cloneStore}
                    activeCloneId={activeCloneId}
                    loading={loading}
                    isSrimandir={isSrimandir}
                    getMasterLinkButton={getMasterLinkButton} // Pass the missing prop
                    filterSearchTitleInput={filterSearchTitleInput}
                    filterSearchIdInput={filterSearchIdInput}
                    setModelConfig={setModelConfig} // Pass the missing prop
                />
                <ManageMasterLinks
                    setMasterLinkDrawer={setMasterLinkDrawer}
                    showMasterLinkDrawer={showMasterLinkDrawer}
                    templeList={templeList}
                    showCreateMasterLinkModal={showCreateMasterLinkModal}
                />
                <CreateMasterLinks show={showLinkCreateModal?.show} masterLink={showLinkCreateModal?.masterLink} linkCreateCallback={callbackFromCreateMasterLink} templeList={templeList} />
                {
                    modelConfig &&
                    <Modal
                        title={modelConfig.type}
                        visible={true}
                        onOk={() => {
                            modelConfig.callback(modelConfig.id, modelConfig.hubId || '')
                            setModelConfig(undefined)
                        }}
                        onCancel={() => { setModelConfig(undefined) }}

                    >
                        <p style={{ textAlign: "center" }}>
                            <b>
                                {
                                    modelConfig.type === MODAL_TYPE.CLONE ?
                                        "Are you sure you want to Clone ?" : modelConfig.type === MODAL_TYPE.ARCHIVE ?
                                            "Are you sure you want to Archive ?" :
                                            "Are you sure you want to Unarchive ?"
                                }
                            </b></p>
                        {
                            modelConfig.hubId !== undefined &&
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <p>
                                    Please choose the hub ,for which you want to clone this store
                                </p>
                                <Select onChange={(id) => setModelConfig({ ...modelConfig, hubId: id })} aria-required style={{ width: '350px' }} placeholder="Select hub" >
                                    {
                                        hubList.map((hub: HubListItem) => (
                                            <Select.Option key={hub.id} value={hub.id}>{hub.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        }
                    </Modal>
                }
            </Container>
        );
    };

export default StoreListTable;