import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import React from 'react'
import { Link } from 'react-router-dom'
import adminMenus from '@/utils/menus/adminMenus'
import { Card, Space, Tabs } from 'antd'

interface Props { }

const HomepageContainer: React.FC<Props> = () => {
    const { monetisationRoles } = useAppContext()

    const monetisationMenu = adminMenus?.find(menu => menu?.id === 'monetisation');
    const categories = monetisationMenu?.children?.filter(child =>
        ['offerings', 'astrologer', 'wallet', 'epuja', 'campaigns']?.includes(child?.id)
    ) || [];

    return (
        <>
            <Card style={{ height: '85%', marginTop: '70px', marginLeft: '50px', marginRight: '50px' }}>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h1>Welcome - Monetisation/Offerings</h1>
                </div>

                <div style={{ position: 'absolute', right: '20px', top: '20px', textAlign: 'left' }}>
                    <h3>Name: {monetisationRoles?.name} ({monetisationRoles?.user_type})</h3>
                    <h3>Email: {monetisationRoles?.email}</h3>
                </div>

                <Tabs defaultActiveKey="0" style={{ width: '100%' }} centered>
                    {categories?.map((menu, index) => (
                        <Tabs.TabPane tab={menu?.title} key={index.toString()}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                                {menu?.linkItem?.map(item => (
                                    <Link
                                        key={item?.id}
                                        to={item?.path}
                                        style={{
                                            backgroundColor: '#e6f4ff',
                                            color: '#69b1ff',
                                            fontWeight: 'bold',
                                            borderRadius: '15px',
                                            margin: '20px',
                                            display: 'inline-flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '200px',
                                            height: '65px',
                                            textAlign: 'center',
                                            transition: 'background-color 0.3s ease, color 0.3s ease'
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.backgroundColor = '#69b1ff';
                                            e.currentTarget.style.color = 'white';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.backgroundColor = '#e6f4ff';
                                            e.currentTarget.style.color = '#69b1ff';
                                        }}
                                    >
                                        {item?.name}
                                    </Link>
                                ))}
                            </div>
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Card>
        </>
    )
}

export default withDefaultLayout(HomepageContainer)
