import { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useAppContext } from '@/components/AppContext'
import withDefaultLayout from '@/hoc/WithDefaultLayout'
import AdminBreadcrumb from '@/ui/organisms/AdminBreadcrumb'
import { Alert, message, Modal, Row, Space, Tag } from 'antd'
import { CreateStore, HubListItem } from '@a4b/api/src/modules/Monetisation/Puja/types'
import type { TablePaginationConfig } from 'antd/es/table'
import Error from '@/components/Error'
import { openErrorNotification, openNotification } from '@/utils'
import { useSearchParams } from 'react-router-dom'
import StoreListTable from './components/StoreListTable'
import { A4B_APPS } from '@/utils/constants'
import MonetisationPujaApi from '@a4b/api/src/modules/Monetisation/Puja'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons'

const StoreList = () => {
  const { networkInstance, languageState, countryState, userProfile, app } = useAppContext()
  const { monetisationPujaApi, monetisationAstroPujaApi } = networkInstance.clientWithHeaders
  const api: MonetisationPujaApi = (app && app === A4B_APPS.SRIMANDIR) ? monetisationPujaApi : monetisationAstroPujaApi
  const isSrimandir = app === A4B_APPS.SRIMANDIR
  const [showStoreEnableModal, setShowStoreEnableModal] = useState(false)
  const [showStoreDisableModal, setShowStoreDisableModal] = useState(false)
  const [pagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 1000,
  })
  const [storeList, setStoreList] = useState<CreateStore[]>([])
  const [currentItem, setCurrentItem] = useState<CreateStore>()
  const [errorPage, setErrorPage] = useState<boolean>(false)
  const [activeCloneId, setActiveCloneId] = useState<string>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hubList, setHubList] = useState<HubListItem[]>([])
  const [copy, setCopy] = useState(false);
  const [showMasterLinkAlert, setMasterLinkAlert] = useState(false);
  const getPageBreadcrumbs = () => [
    {
      path: isSrimandir ? '/monetisation/puja/puja-updates' : '/astro/puja/puja-updates',
      breadcrumbName: 'Puja Updates',
    },
    {
      path: isSrimandir ? '/monetisation/puja' : '/astro/puja',
      breadcrumbName: 'Hub List',
    },
    {
      path: isSrimandir ? '/monetisation/puja/temples' : '/astro/puja/temples',
      breadcrumbName: 'Manage Stores',
    },
  ]

  const updateFilters = (updateType: 'sortBy' | 'isArchive' | 'isActive' | 'serviceType', value?: 'true' | 'false' | 'created_at' | 'position' | 'pooja') => {
    const paramMapping: Record<string, string> = {
      sortBy: 'sort_by',
      isArchive: 'is_archive',
      isActive: 'is_active',
      serviceType: 'service_type',
    };

    const paramName = paramMapping[updateType]

    if (paramName && value !== undefined) {
      searchParams?.set(paramName, value)
    } else if (paramName) {
      searchParams?.delete(paramName)
    }
    setSearchParams(searchParams)
  }

  const getHubList = useCallback((async () => {
    try {
      const hubList = await api.hub.getHubList({
        limit: 1000,
        offset: 0,
      })

      setHubList(hubList?.data?.data.hubs)
    } catch (error) {
      console.log(error)
    }
  }), [api])

  useEffect(() => {
    getHubList()
  }, [getHubList])


  const getStoreList = useCallback((async () => {
    try {
      setIsLoading(true)
      const storeList = await api.storesApi.getStoreList({
        limit: pagination.pageSize || 1000,
        offset: 0,
      }, countryState?.countryCode, undefined, undefined,
        searchParams.get('sort_by') as ('created_at' | 'position') || 'created_at',
        searchParams.get('is_archive'),
        searchParams.get('is_active'),
        searchParams.get('service_type' as ('pooja')) || 'pooja'
      )

      setStoreList(storeList?.data?.data?.store_list)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setErrorPage(true)
      message.error({
        content: 'Error while fetching store list',
        duration: 3,
      })
      console.log(error)
    }
  }), [api.storesApi, pagination.pageSize, countryState?.countryCode, searchParams])

  useEffect(() => {
    getStoreList()
  }, [getStoreList, languageState.monetisationLanguage, countryState?.countryCode])

  const cloneStore = useCallback(async (storeId, hubId: string) => {
    setActiveCloneId(storeId);
    try {
      const res = await api.storesApi.cloneStore(storeId, userProfile?.email || 'not found', hubId)
      getStoreList();
      openNotification("success", { message: res.data.data })
    } catch (error) {
      console.log(error)
      openErrorNotification(error);
    }
    setActiveCloneId(undefined);
  }, [getStoreList, api.storesApi, userProfile?.email])


  const onStatusChange = (item: CreateStore) => {
    setCurrentItem(item)
    if (item.active) {
      setShowStoreDisableModal(true)
    } else {
      setShowStoreEnableModal(true)
    }
  }
  const onModalOk = async () => {
    message.loading({
      content: 'Updating store status',
      duration: 1,
      key: 'update_status',
    })
    const payload = {
      active: !currentItem?.active,
      language_code: languageState.monetisationLanguage,
      country_code: countryState?.countryCode,
    }
    try {
      await api.storesApi.updateStoreStatus(
        currentItem?.id || '',
        payload
      )
      setShowStoreEnableModal(false)
      setShowStoreDisableModal(false)
      message.success({
        content: 'Store status updated sucessfully',
        duration: 3,
        key: 'update_status',
      })
      getStoreList()
    } catch (error) {
      setShowStoreEnableModal(false)
      setShowStoreDisableModal(false)

      if (typeof error === "object" && error !== null && "response" in error) {
        const response = (error as any).response; // Or define a more specific type if known
        if (
          response?.data?.error?.args ===
          "UpdateStoreState: store-linked-with-active-master-slug"
        ) {
          setMasterLinkAlert(true)
          return
        }
      }

      message.error({
        content: 'Error while updating store status',
        duration: 2,
        key: 'update_status',
      })

      console.log(error)
    }
  }

  const onModalCancel = () => {
    setShowStoreEnableModal(false)
    setShowStoreDisableModal(false)
  }

  const archiveStore = async (storeId: string) => {
    try {
      await api.storesApi.archiveStore(storeId, {
        email: userProfile.email,
        archive: true
      })
      getStoreList();
      openNotification("success", { message: "Archive status updated." })
    } catch (error) {
      openNotification("error", { message: "Unable to archive store something went worng" })
    }
  }

  const unarchiveStore = async (storeId: string) => {
    try {
      await api.storesApi.archiveStore(storeId, {
        email: userProfile.email,
        archive: false
      })
      getStoreList()
      openNotification("success", { message: "Archive status updated." })
    } catch (error) {
      openNotification("error", { message: "Unable to archive store something went worng" })
    }
  }
  const handleOnCopy = () => {
    setCopy(true)
    setTimeout(() => {
      setCopy(false)
    }, 3000);
  }

  return (
    <div style={{ padding: '0 40px' }}>
      {showMasterLinkAlert && <Row justify='center' style={{ position: "fixed", top: "65px", zIndex: "100" }}>
        <Alert
          message="Store conflict with masterlink"
          description="This store is associated with an active master slug, so this action is restricted"
          type="error"
          className='fixed-alert'
          action={
            <Space direction="vertical" style={{ cursor: "pointer" }}>
              <CopyToClipboard text={currentItem?.id!}
                onCopy={handleOnCopy}>
                <Tag color={copy ? "#87d068" : "#2db7f5"}><CopyOutlined />{copy ? "  Copied" : "  Copy store ID"}</Tag>
              </CopyToClipboard>
            </Space>
          }
          closable
          onClose={() => setMasterLinkAlert(false)}
        />
      </Row>}
      {errorPage ?
        <Error text='Error while fetching Stores!' /> :
        <>
          <AdminBreadcrumb routes={getPageBreadcrumbs()} />
          <StoreListTable
            onStatusChange={onStatusChange}
            templeList={storeList}
            archiveStore={archiveStore}
            unarchiveStore={unarchiveStore}
            cloneStore={cloneStore}
            activeCloneId={activeCloneId}
            sortBy={searchParams.get('sort_by') as ('created_at' | 'position') || 'created_at'}
            isArchive={searchParams.get('is_archive') as ('true' | 'false')}
            isActive={searchParams.get('is_active') as ('true' | 'false')}
            updateFilters={updateFilters}
            loading={isLoading}
            hubList={hubList}
          />

          <Modal
            title='Activate Store'
            visible={showStoreEnableModal}
            onOk={onModalOk}
            onCancel={onModalCancel}
            okText=' Yes, Activate'
          >
            <p>Are you sure to activate this store ?</p>
          </Modal>
          <Modal
            title='Deactivate Store'
            visible={showStoreDisableModal}
            onOk={onModalOk}
            onCancel={onModalCancel}
            okText='Yes, Deactivate'
          >
            <p>Are you sure to Deactivate this store ?</p>
          </Modal>

        </>
      }

    </div>
  )
}

export default withDefaultLayout(StoreList)
